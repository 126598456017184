import { Box } from "@mui/material";

import ModalUI from "components/UI/Modal/ModalUI";
import CustomizedButton from "components/Custom/CustomizedButton";
import SalesAcceptForm from "./index";
import { SalesModelType } from "generated/sales";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  documentType: SalesModelType;
};

const title = {
  quotation: "ยืนยันการตอบรับใบเสนอราคา",
  billing_note: "แนบหลักฐานการชำระ",
  delivery_trip: "บันทึกวันจ่าย",
  maintenance: "",
};

const SalesAcceptModal = ({
  open,
  handleClose,
  onSubmit,
  documentType,
}: Props) => {
  const buttonTitle = documentType === "delivery_trip" ? "ยืนยัน" : "บันทึก";
  return (
    <ModalUI
      title={title[documentType]}
      titleSize={"24px"}
      maxWidth="xs"
      open={open}
      handleClose={handleClose}
      action={
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={handleClose}
          />
          <CustomizedButton
            title={buttonTitle}
            variant="contained"
            onClick={onSubmit}
          />
        </Box>
      }
    >
      <SalesAcceptForm documentType={documentType} isModal />
    </ModalUI>
  );
};

export default SalesAcceptModal;

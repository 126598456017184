import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { dateFilterParams } from "utils/AgGridFilter";
import { statusValueFormatter } from "utils/Formatter/Global";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import { typeValueFormatter } from "utils/Formatter/Logistic/Vehicle";
// import { formatNumber } from "utils/Global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { USERS_AGGRID } from "services/AgGrid/UserAgGrid";
import { IUser } from "types/Auth/user";

export const useDeliveryTripColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "id",
        headerName: "",
        sort: "desc",
        hide: true,
        filter: "agNumberColumnFilter",
        suppressColumnsToolPanel: true,
        minWidth: 200,
        flex: 1,
      },
      {
        field: "unique_id",
        headerName: t("logistic.delivery_trip.unique_id"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "customer_name",
        headerName: t("logistic.delivery_trip.customer_details.name"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      // {
      //   field: "income_total_amount",
      //   headerName: t("logistic.delivery_trip.income_total_amount"),
      //   filter: "agNumberColumnFilter",
      //   minWidth: 200,
      //   flex: 1,
      //   cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   valueFormatter: (params: ValueFormatterParams) =>
      //     params.value ? formatNumber(params.value) : "",
      // },
      // {
      //   field: "expenses_total_amount",
      //   headerName: t("logistic.delivery_trip.expenses_total_amount"),
      //   filter: "agNumberColumnFilter",
      //   minWidth: 200,
      //   flex: 1,
      //   cellStyle: { display: "flex", justifyContent: "flex-end" },
      //   valueFormatter: (params: ValueFormatterParams) =>
      //     params.value ? formatNumber(params.value) : "",
      // },
      {
        field: "vehicle_no",
        headerName: t("logistic.delivery_trip.vehicle.vehicle_no"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "vehicle_license_plate",
        headerName: t("logistic.delivery_trip.vehicle.license_plate"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "vehicle_type",
        headerName: t("logistic.delivery_trip.vehicle.vehicle_type"),
        filter: "agSetColumnFilter",
        minWidth: 200,
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) =>
          typeValueFormatter(params.value),
        filterParams: {
          values: ["head", "four_wheel", "six_wheel", "ten_wheel"],
          valueFormatter: (params: ValueFormatterParams) =>
            typeValueFormatter(params.value),
        },
      },
      {
        field: "trailer_license_plate",
        headerName: t("logistic.delivery_trip.trailer.license_plate"),
        filter: "agTextColumnFilter",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "driver_object_list",
        headerName: t("logistic.vehicle.driver"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            try {
              const graphQLClient: GraphQLClient =
                createGraphQLClientWithMiddleware("general");
              const { UsersFindManyAggrid } = await graphQLClient.request(
                USERS_AGGRID,
                {
                  aggridInput: {
                    startRow: 0,
                    endRow: 9999,
                    filterModel: {
                      position: {
                        filterType: "text",
                        type: "equals",
                        filter: "พนักงานขนส่ง",
                      },
                      status: {
                        values: [1],
                        filterType: "set",
                      },
                    },
                    sortModel: [],
                  },
                }
              );
              const userName = UsersFindManyAggrid.results.map(
                (user: IUser) => user.first_name + " " + user.last_name
              );
              params.success(userName);
            } catch (err) {
              params.fail();
            }
          },
        },
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value && params.value.length > 0) {
            return <CustomizedAvatar avatars={params.value} />;
          }
        },
        minWidth: 200,
      },
      {
        field: "plan_start_date",
        headerName: t("logistic.delivery_trip.start_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "plan_end_date",
        headerName: t("logistic.delivery_trip.end_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
        minWidth: 200,
        flex: 1,
      },
      {
        field: "aggrid_status",
        headerName: t("status.index"),
        minWidth: 200,
        flex: 1,
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          if (params.value === "late") {
            return <CustomizedStatus status={params.data.main_status} />;
          } else {
            return <CustomizedStatus status={params.value} />;
          }
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
        filterParams: {
          values: [
            "wait_deliver",
            "delivering",
            "late",
            "finished",
            "cancelled",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
      {
        field: "main_status",
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["wait_deliver", "delivering", "finished"],
        },
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          return <CustomizedStatus status={params.value} />;
        },
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: "flag_payment_status",
        headerName: t("logistic.delivery_trip.payment.index"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["paid", "not_paid", "wait_finished"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
        cellRenderer: (params: ICellRendererParams<any, string>) => {
          return <CustomizedStatus status={params.value} />;
        },
        minWidth: 200,
        flex: 1,
        cellStyle: {
          display: "flex",
          alignItems: "center",
        },
      },
      {
        field: "payment_date",
        headerName: t("logistic.delivery_trip.payment.payment_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "";
          }
        },
        minWidth: 200,
        flex: 1,
      }

    ]);
  }, [t]);
  return columnDefs;
};

import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BinLocationsFindAllQuery,
  InventorySettingsByTypeQuery,
  InventorySettingType,
  useBinLocationsFindAllQuery,
  useInventorySettingsByTypeQuery,
  useWarehousesFindAllQuery,
  WarehousesFindAllQuery,
} from "generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { dateFilterParams } from "utils/AgGridFilter";
import { formatDate } from "utils/Date";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "components/Custom/CustomizedLetterAvatar";
import { formatNumber } from "utils/Global";

export const useTraceEntryReportColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch } = useWarehousesFindAllQuery<WarehousesFindAllQuery>(
    graphQLClient,
    {},
    {
      enabled: false,
    }
  );

  const { refetch: refecthBinLocation } =
    useBinLocationsFindAllQuery<BinLocationsFindAllQuery>(
      graphQLClient,
      {},
      {
        enabled: false,
      }
    );

  const { refetch: refetchUOM } =
    useInventorySettingsByTypeQuery<InventorySettingsByTypeQuery>(
      graphQLClient,
      {
        type: InventorySettingType.Unit,
      },
      {
        enabled: false,
      }
    );

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "created_date",
        headerName: t("inventory.stockEntry.createdDate"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "posted_date",
        headerName: t("inventory.goods_receive.table.column.posted_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
      },
      {
        field: "source_warehouse_unique_id",
        headerName: t("inventory.goods_transfer.source_warehouse_id"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map(
              (warehouse) => warehouse.unique_id
            );
            params.success(formatValues);
          },
        },
        hide: true,
      },
      {
        field: "source_warehouse_name",
        headerName: t("inventory.goods_transfer.source_warehouse"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map((warehouse) => warehouse.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "source_bin_location_name",
        headerName: "สถานที่ต้นทาง",
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: binLocationResult } = await refecthBinLocation();
            const values = binLocationResult?.BinLocationsFindAll;
            const formatValues = values?.map((binlocation) => binlocation.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "destination_warehouse_unique_id",
        headerName: t("inventory.goods_transfer.destination_warehouse_id"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map(
              (warehouse) => warehouse.unique_id
            );
            params.success(formatValues);
          },
        },
        hide: true,
      },
      {
        field: "destination_warehouse_name",
        headerName: t("inventory.goods_transfer.destination_warehouse"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: warehousesResult } = await refetch();
            const values = warehousesResult?.WarehousesFindAll;
            const formatValues = values?.map((warehouse) => warehouse.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "destination_bin_location_name",
        headerName: "สถานที่ปลายทาง",
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: binLocationResult } = await refecthBinLocation();
            const values = binLocationResult?.BinLocationsFindAll;
            const formatValues = values?.map((binlocation) => binlocation.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "item_unique_id",
        headerName: t("inventory.item.unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "seller_sku",
        headerName: t("inventory.item.sku"),
        filter: "agTextColumnFilter",
        hide: true,
        valueFormatter: (params) => (params.value ? params.value : "-"),
      },
      {
        field: "item_name",
        headerName: t("inventory.item.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "serial_no",
        headerName: t("inventory.item.serial_no"),
        filter: "agTextColumnFilter",
      },
      {
        field: "finished_qty",
        headerName: "จำนวนที่รับ/จ่ายจริง",
        filter: "agNumberColumnFilter",
        valueFormatter: (params) => formatNumber(params.value || 0),
        cellStyle: { display: "flex", justifyContent: "flex-end" },
      },
      {
        field: "uom",
        headerName: t("inventory.item.warehouse.uom.index"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: any) => {
            const { data: categoriesResult } = await refetchUOM();
            const values = categoriesResult?.InventorySettingsByType;
            const formatValues = values?.map((unit) => unit.name);
            params.success(formatValues);
          },
        },
      },
      {
        field: "type",
        headerName: t("inventory.stockEntry.entryDocumentType"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "good_receive",
            "good_issue",
            "good_transfer",
            "good_adjust",
          ],
          valueFormatter: ({ value }: { value: string }) => {
            switch (value) {
              case "good_receive":
                return t("inventory.goods_receive.index");
              case "good_issue":
                return t("inventory.goods_issue.index");
              case "good_transfer":
                return t("inventory.goods_transfer.index");
              case "good_adjust":
                return t("inventory.goods_adjust.index");
              default:
                return "-";
            }
          },
        },
        valueFormatter: ({ value }: { value: string }) => {
          switch (value) {
            case "good_receive":
              return t("inventory.goods_receive.index");
            case "good_issue":
              return t("inventory.goods_issue.index");
            case "good_transfer":
              return t("inventory.goods_transfer.index");
            case "good_adjust":
              return t("inventory.goods_adjust.index");
            default:
              return "-";
          }
        },
      },
      {
        field: "reference_unique_id",
        headerName: t("inventory.stockEntry.referenceDocument"),
        filter: "agTextColumnFilter",
      },
      {
        field: "scanned_by_name",
        headerName: t("inventory.trace.movement_by"),
        filter: "agTextColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            const { scanned_by_img_url, scanned_by } = params.data;
            if (scanned_by_img_url?.length > 0) {
              return (
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: scanned_by.user_unique_id,
                      first_name: scanned_by.first_name,
                      last_name: scanned_by.last_name,
                      img_url: scanned_by_img_url,
                    },
                  ]}
                />
              );
            } else {
              return <CustomizedLetterAvatar name={params.value} />;
            }
          }
        },
        minWidth: 200,
        flex: 1,
      },
    ]);
  }, [t, refetch, refecthBinLocation, refetchUOM]);
  return columnDefs;
};

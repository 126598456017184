import { Box, Grid, Typography } from "@mui/material";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";

const FuelInfo = () => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IDeliveryTrip>();
  const { t } = useTranslation();

  const [disabled] = useDisable();

  const mainStatus = useWatch({
    control,
    name: "main_status",
  });
  const aggrid_status = useWatch({
    control,
    name: "aggrid_status",
  });
  const flagStatus = useWatch({
    control,
    name: "flag_status",
  });

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;
  const isShow = ["delivering", "finished", "cancelled"].includes(status);
  const isFinished = status === "finished";

  return (
    <CustomizedBox margin={0}>
      <Typography fontWeight={600} mb={1}>
        {t("logistic.delivery_trip.fuel.index")}
      </Typography>
      <Grid container spacing={1.5}>
        {isShow && (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="fuel.appa"
                label={t("logistic.delivery_trip.fuel.appa")}
                error={Boolean(errors.fuel?.appa)}
                helperText={errors.fuel?.appa?.message}
                required
                onChange={(value) => {
                  const actual_fleet = getValues(`fuel.actual_fleet`);
                  const other = getValues(`fuel.other`);
                  const total_quota = getValues(`fuel.total_quota`);
                  const total_usage =
                    (total_quota || 0) -
                    ((value || 0) + (actual_fleet || 0) + (other || 0));
                  setValue("fuel.total_usage", total_usage);
                }}
                endAdornmentMessage="ลิตร"
                viewMode={disabled}
                disabled={isFinished}
                decimalScale={2}
                showZero
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="fuel.actual_fleet"
                label={t("logistic.delivery_trip.fuel.actual_fleet")}
                error={Boolean(errors.fuel?.actual_fleet)}
                helperText={errors.fuel?.actual_fleet?.message}
                onChange={(value) => {
                  const appa = getValues(`fuel.appa`);
                  const other = getValues(`fuel.other`);
                  const total_quota = getValues(`fuel.total_quota`);
                  const total_usage =
                    (total_quota || 0) -
                    ((value || 0) + (appa || 0) + (other || 0));
                  setValue("fuel.total_usage", total_usage);
                }}
                endAdornmentMessage="ลิตร"
                viewMode={disabled}
                disabled={isFinished}
              />
            </Grid>
            <Box width="100%" />
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                fullWidth
                control={control}
                name="fuel.other"
                label={t("logistic.delivery_trip.fuel.other")}
                error={Boolean(errors.fuel?.other)}
                helperText={errors.fuel?.other?.message}
                onChange={(value) => {
                  const actual_fleet = getValues(`fuel.actual_fleet`);
                  const appa = getValues(`fuel.appa`);
                  const total_quota = getValues(`fuel.total_quota`);
                  const total_usage =
                    (total_quota || 0) -
                    ((value || 0) + (actual_fleet || 0) + (appa || 0));
                  setValue("fuel.total_usage", total_usage);
                }}
                endAdornmentMessage="ลิตร"
                viewMode={disabled}
                disabled={isFinished}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={4}>
          <ControlledNumberTextField
            fullWidth
            control={control}
            name={"fuel.total_quota"}
            label={t("logistic.delivery_trip.fuel.total_quota")}
            error={Boolean(errors.fuel?.total_quota)}
            helperText={errors.fuel?.total_quota?.message?.toString()}
            onChange={(value) => {
              const actual_fleet = getValues(`fuel.actual_fleet`);
              const other = getValues(`fuel.other`);
              const appa = getValues(`fuel.appa`);
              const total_usage =
                (value || 0) -
                ((actual_fleet || 0) + (other || 0) + (appa || 0));
              setValue("fuel.total_usage", total_usage);
            }}
            endAdornmentMessage="ลิตร"
            required
            viewMode={disabled}
            disabled={isFinished}
            decimalScale={2}
            showZero
          />
        </Grid>
        {isShow && (
          <Grid item xs={12} sm={12} md={4}>
            <ControlledNumberTextField
              fullWidth
              control={control}
              name="fuel.total_usage"
              label={t("logistic.delivery_trip.fuel.total_usage")}
              error={Boolean(errors.fuel?.total_usage)}
              helperText={errors.fuel?.total_usage?.message}
              required
              endAdornmentMessage="ลิตร"
              disabled
              viewMode={disabled}
              allowNegative
            />
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default FuelInfo;

import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "types/global";
import { IAccessPermission, IPermissionContext } from "types/Auth/role";
import { useTranslation } from "react-i18next";

export const useMaintenanceOption = (
  status?: string | null,
  isEditing?: boolean,
  type?: string | null,
  is_insurance_fully_paid?: boolean | null,
  maintenancePermissions?: IAccessPermission | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        maintenancePermissions?.update && {
          value: "แก้ไข",
          disabled: isEditing,
        },
        maintenancePermissions?.create && {
          value: "คัดลอก",
          disabled: false,
        },
        maintenancePermissions?.cancel && {
          value: "ยกเลิก",
          disabled: false,
        },
      ] as IMenuOption[],
    [isEditing, maintenancePermissions]
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions(
          [defaultOptions?.[1], defaultOptions?.[2]].filter(Boolean)
        ); // ยกเลิก, คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions?.[1]].filter(Boolean)); // คัดลอก
        break;
      case "finished":
        if (type === "CLAIM" && !is_insurance_fully_paid) {
          setSelectedModifyOptions(
            [
              {
                value: "ได้รับเงินเคลมครบ",
              },
              defaultOptions?.[1],
            ].filter(Boolean)
          ); // ได้รับเงินเคลมครบ,คัดลอก
          break;
        }
        setSelectedModifyOptions([defaultOptions?.[1]].filter(Boolean)); // คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultOptions?.filter(Boolean));
        break;
    }
  }, [defaultOptions, status, type, is_insurance_fully_paid]);

  return selectModifyOptions;
};

export const useMaintenanceCreate = (
  status?: string | null,
  isMaxQty?: boolean,
  permissions?: IPermissionContext | null
) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () =>
      [
        permissions?.goods_issue?.create && {
          value: t("inventory.goods_issue.index"),
        },
      ] as IMenuOption[],
    [permissions, t]
  );

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    if (status === "finished" && !isMaxQty) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  }, [status, isMaxQty]);

  useEffect(() => {
    switch (status) {
      case "finished":
        setOptions(defaultOptions.filter(Boolean));
        break;
      default:
        setOptions([]);
    }
  }, [defaultOptions, status]);

  return {
    selectCreateOptions: options,
    showCreateOptions: showOptions,
  };
};

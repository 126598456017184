import { renderAddressString } from "../utils/Formatter/Global";

export const usePDFMultiplePage = (
  data: any,
  documentType: string,
  rowWidth: number, // item name and description cell width
  maxHeightWithoutFooter: number, //Max height of table body (Footer is summary + remark & tag)
  maxHeightWithFooter: number,
  leftHeaderMinHeight: number,
  rightHeaderMinHeight: number,
  leftFooterMinHeight: number = 0,
  rightFooterMinHeight: number = 0,
  reservedHeight: number = 0,
  descriptionRowWidth?: number
) => {
  const baseHeaderHeight = Math.max(leftHeaderMinHeight, rightHeaderMinHeight);
  const baseFooterHeight = Math.max(leftFooterMinHeight, rightFooterMinHeight);

  let paginatedItems: any[][] = [];
  let currentItems: any[] = [];
  let currentTableHeight = 0;
  let footerHeight = 0;

  let itemList =
    documentType === "maintenance" ? data?.ma_list : data?.item_list;

  let secondItemList = documentType === "maintenance" ? data?.ma_item_list : [];

  //Calculate header height

  const calculateLeftHeaderHeight = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const companyAddressMaxWidth = 340.74;
    const addressMaxWidth = 303.29;
    const nameMaxWidth = 303.29;

    if (!context) {
      throw new Error("Cannot get 2D context");
    }

    context.font = "10.66px";

    const companyAddress = ``;
    //`${defaultAddress?.name} ${defaultAddress?.sub_district} ${defaultAddress?.district} ${defaultAddress?.province} ${defaultAddress?.postal_code}

    const customerAddress =
      renderAddressString(data?.delivery_address || {}) || "-";

    let name = `${data?.supplier_unique_id} - ${data?.supplier_details?.name}`;

    if (documentType === "quotation") {
      name = `${data?.contact_unique_id} - ${data?.customer_details?.customer_name}`;
    }

    let additionalHeight = 0;

    let companyAddressWidth = context.measureText(companyAddress).width;
    if (companyAddressWidth > companyAddressMaxWidth) {
      const lines = Math.ceil(companyAddressWidth / addressMaxWidth);
      additionalHeight += (lines - 1) * 15.99;
    }

    let customerAddressWidth = context.measureText(customerAddress).width;
    if (customerAddressWidth > addressMaxWidth) {
      const lines = Math.ceil(customerAddressWidth / addressMaxWidth);
      additionalHeight += (lines - 1) * 21.33;
    }

    let nameWidth = context.measureText(name).width;
    if (nameWidth > nameMaxWidth) {
      const lines = Math.ceil(nameWidth / nameMaxWidth);
      additionalHeight += (lines - 1) * 21.33;
    }

    return leftHeaderMinHeight + additionalHeight;
  };

  const calculateRightHeaderHeight = () => {
    const referenceIds = data?.reference_document_list;
    let additionalLines = 0;

    if (referenceIds && referenceIds.length > 0) {
      const refLines = Math.ceil(referenceIds.length / 2);
      additionalLines = refLines - 1;
    }

    return rightHeaderMinHeight + additionalLines * 21.33;
  };

  //Calculate Footer Height

  const calculateLeftFooterHeight = () => {
    const remark = data?.remark ? data.remark : "-";
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "10.66px";

    let lines = Math.ceil(context.measureText(remark).width / 361.84);

    const additionalRemarkLines = lines; // maybe lines - 1 or - 2 (need better method)

    return leftFooterMinHeight + additionalRemarkLines * 16;
  };

  const calculateRightFooterHeight = () => {
    const additional_discount = data?.additional_discount > 0 ? 1 : 0;
    const vat_exem = data?.vat_exempted_amount > 0 ? 1 : 0;
    const zero_percent = data?.vat_0_percent_amount > 0 ? 1 : 0;
    const seven_percent = data?.vat_7_percent_amount > 0 ? 1 : 0;
    const vat_amount = data?.vat_amount > 0 ? 1 : 0;

    const additionalLines =
      additional_discount +
      vat_exem +
      zero_percent +
      seven_percent +
      vat_amount;

    return rightFooterMinHeight + additionalLines * 21.33;
  };

  const leftHeaderHeight = calculateLeftHeaderHeight();
  const rightHeaderHeight = calculateRightHeaderHeight();

  const additionalHeaderHeight =
    Math.max(leftHeaderHeight, rightHeaderHeight) - baseHeaderHeight;

  // if there is footer or summary
  if (leftFooterMinHeight > 0 || rightFooterMinHeight > 0) {
    const leftFooterHeight = calculateLeftFooterHeight();
    const rightFooterHeight = calculateRightFooterHeight();

    footerHeight = Math.max(leftFooterHeight, rightFooterHeight);

    const additionalFooterHeight = footerHeight - baseFooterHeight;

    maxHeightWithFooter -= additionalHeaderHeight + additionalFooterHeight;
  } else {
    maxHeightWithFooter -= additionalHeaderHeight;
  }
  maxHeightWithoutFooter -= additionalHeaderHeight;

  let isFirstPage = paginatedItems.length === 0;

  //Calculate each row's height in table
  itemList?.forEach((item: any, index: number) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "10.66px";

    let numberOfLines = 1;
    let rowHeight = 0;
    const { item_name: itemName, item_sku_desc: itemSkuDescription } = item;

    const itemDescription =
      documentType === "maintenance" ? item?.description : item?.item_desc;

    const nameText = itemName;
    const nameWidth = context.measureText(nameText).width;
    const nameLines = Math.ceil(nameWidth / rowWidth);
    numberOfLines += nameLines > 1 ? nameLines : 0;

    // Handle multiline description
    const descriptionLines = itemDescription
      ? itemDescription?.split("\n")
      : itemSkuDescription?.split("\n");
    descriptionLines?.forEach((line: string) => {
      const lineWidth = context.measureText(line).width;
      const linesRequired = Math.ceil(
        lineWidth / (descriptionRowWidth ?? rowWidth)
      );
      numberOfLines += linesRequired > 1 ? linesRequired : 1;
    });

    if (numberOfLines > 1) {
      rowHeight = 48.78 + (numberOfLines - 1) * 15.99;
    } else {
      rowHeight = 48.78;
    }

    if (index === itemList.length - 1 && documentType === "purchase_order") {
      if (
        currentTableHeight +
          rowHeight +
          (isFirstPage ? reservedHeight : 0) +
          25 >
        maxHeightWithoutFooter
      ) {
        paginatedItems.push(currentItems);
        currentItems = [];
        currentTableHeight = rowHeight;
        currentItems.push(item);
      } else {
        currentTableHeight += rowHeight;
        currentItems.push(item);
      }
    } else {
      if (
        currentTableHeight + rowHeight + (isFirstPage ? reservedHeight : 0) >
        maxHeightWithoutFooter
      ) {
        paginatedItems.push(currentItems);
        currentItems = [];
        currentTableHeight = rowHeight;
        currentItems.push(item);
      } else {
        currentTableHeight += rowHeight;
        currentItems.push(item);
      }
    }
    if (paginatedItems.length > 0) {
      isFirstPage = false;
    }
  });

  let firstInstance = true;

  secondItemList?.forEach((item: any, index: number) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot get 2D context");
    }
    context.font = "10.66px";

    let numberOfLines = 1;
    let rowHeight = 0;
    const {
      item_name: itemName,
      item_desc: itemDescription,
      item_sku_desc: itemSkuDescription,
    } = item;

    const nameText = itemName;
    const nameWidth = context.measureText(nameText).width;
    const nameLines = Math.ceil(nameWidth / rowWidth);
    numberOfLines += nameLines > 1 ? nameLines : 0;

    // Handle multiline description
    const descriptionLines = itemDescription
      ? itemDescription?.split("\n")
      : itemSkuDescription?.split("\n");
    descriptionLines?.forEach((line: string) => {
      const lineWidth = context.measureText(line).width;
      const linesRequired = Math.ceil(lineWidth / rowWidth);
      numberOfLines += linesRequired > 1 ? linesRequired : 1;
    });

    if (numberOfLines > 1) {
      rowHeight = 48.78 + (numberOfLines - 1) * 15.99;
    } else {
      rowHeight = 48.78;
    }

    if (
      currentTableHeight + rowHeight + (isFirstPage ? reservedHeight : 0) >
      maxHeightWithoutFooter
    ) {
      paginatedItems.push(currentItems);
      currentItems = [];
      currentTableHeight = rowHeight;
      currentItems.push({
        ...item,
        pdf_table_type: "second",
        first_instance: firstInstance,
      });
    } else {
      currentTableHeight += rowHeight;
      currentItems.push({
        ...item,
        pdf_table_type: "second",
        first_instance: firstInstance,
      });
    }
    if (firstInstance) {
      firstInstance = false;
    }
    if (paginatedItems.length > 0) {
      isFirstPage = false;
    }
  });

  paginatedItems.push(currentItems);

  if (currentTableHeight > maxHeightWithFooter) {
    paginatedItems.push([]);
  }

  let startNumbers: number[] = [];
  let secondStartNumbers: number[] = [];

  // Initialize counters to track the current index in each list
  let currentFirstIndex = 0;
  let currentSecondIndex = 0;

  paginatedItems.forEach((pageItems, pageIndex) => {
    if (secondItemList && secondItemList.length > 0) {
      if (pageIndex === 0) {
        // First page starts at 1 for both lists
        startNumbers.push(1);
        secondStartNumbers.push(1);

        // Calculate initial indices for both item types
        currentFirstIndex += pageItems.filter((item) => !item.unique_id).length;
        currentSecondIndex += pageItems.filter((item) => item.unique_id).length;
      } else {
        // Calculate start number for the first item list
        if (pageItems.some((item) => !item.unique_id)) {
          startNumbers.push(currentFirstIndex + 1);
          currentFirstIndex += pageItems.filter(
            (item) => !item.unique_id
          ).length;
        } else {
          startNumbers.push(0); // No first items on this page
        }

        // Calculate start number for the second item list
        if (pageItems.some((item) => item.unique_id)) {
          secondStartNumbers.push(currentSecondIndex + 1);
          currentSecondIndex += pageItems.filter(
            (item) => item.unique_id
          ).length;
        } else {
          secondStartNumbers.push(0); // No second items on this page
        }
      }
    } else {
      // Apply the original simpler logic if no second item list exists
      if (pageIndex === 0) {
        startNumbers.push(1);
        currentFirstIndex = pageItems.length;
      } else {
        startNumbers.push(currentFirstIndex + 1);
        currentFirstIndex += pageItems.length;
      }
    }
  });

  return {
    paginatedItems,
    startNumbers,
    secondStartNumbers,
  };
};

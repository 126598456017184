import { Box, Grid, IconButton, Typography } from "@mui/material";
import ControlledTextField from "components/Controller/ControlledTextField";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import Confirmation from "components/UI/Confirmation";
import { useConfirmation } from "hooks/use-confirmation";
import { useDisable } from "hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import { IMenuOption, ISelectOption } from "types/global";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import GreenCheckIcon from "components/UI/GreenCheckIcon";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import {
  SalesModelType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
} from "generated/sales";
import { useCallback, useEffect } from "react";
import CancelConfirmation from "components/UI/CancelConfirmation";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CustomizedChips from "components/Custom/CustomizedChips";
import { IMaintenance } from "types/Logistic/maintenance";
import {
  useMaintenanceOption,
  useMaintenanceCreate,
} from "hooks/Logistic/Maintenance/use-maintenance-option";
import ControlledSelect from "components/Controller/ControlledSelect";
import DriverListForm from "components/Form/DriverList";
import {
  copyMaintenanceHandler,
  createGoodsIssueFromMaintenance,
} from "utils/Formatter/Logistic/Maintenance";

type Props = {
  editClickHandler: () => void;
  cancelHandler?: () => void;
  fullPaidInsuranceHandler?: () => void;
  isEdit: boolean;
};

const MaintenanceHeader = ({
  cancelHandler,
  editClickHandler,
  fullPaidInsuranceHandler,
  isEdit,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IMaintenance>();
  const navigate = useNavigate();
  const {
    state: { permissions, authUser },
  } = useStateContext();

  const [disabled] = useDisable();

  const mainStatus = useWatch({ control, name: "main_status" });
  const aggrid_status = useWatch({
    control,
    name: "aggrid_status",
  });
  const flagStatus = useWatch({ control, name: "flag_status" });

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("logistic.maintenance.index")}`,
      disabled: !id,
    },
  ];

  const copyDocumentHandler = async () => {
    const maintenance = getValues();
    const formatMaintenance = await copyMaintenanceHandler(maintenance);
    navigate("/logistic/maintenance/add", {
      state: formatMaintenance,
    });
  };

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.Maintenance,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);
  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  const {
    confirmation: fullyPaidInsuranceConfirmation,
    openConfirmationHandler: openFullyPaidInsuranceConfirmation,
    closeConfirmationHandler: closeFullyPaidInsuranceConfirmation,
    submitConfirmationHandler: submitFullyPaidInsuranceConfirmation,
  } = useConfirmation(fullPaidInsuranceHandler);

  const is_insurance_fully_paid = useWatch({
    control,
    name: "is_insurance_fully_paid",
  });

  const type = useWatch({ control, name: "type" });

  const watchItemList = useWatch({
    control,
    name: "ma_item_list",
  });
  const isMaxQty = watchItemList?.every(
    (item) => (item.qty_issued || 0) >= item.qty
  );

  const selectModifyOptions = useMaintenanceOption(
    status,
    isEdit,
    type,
    is_insurance_fully_paid,
    permissions?.maintenance
  );

  const { selectCreateOptions, showCreateOptions } = useMaintenanceCreate(
    status,
    isMaxQty,
    permissions
  );

  const typeOptions: ISelectOption[] = [
    {
      label: "ปกติ",
      value: "NORMAL",
      disabled: false,
    },
    {
      label: "เคลม",
      value: "CLAIM",
      disabled: false,
    },
  ];

  const disabledStatus = ["maintenancing", "finished"].includes(status);

  const uniqueId = useWatch({ control, name: "unique_id" });

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const filteredRefDocument = reference_document_list?.filter(
    (ref) => !ref.hidden
  );

  return (
    <Box maxWidth={1040} mt={status === "finished" ? 0 : 2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              {t("logistic.maintenance.index")}
            </Typography>
            <CustomizedStatus status={status} />
            {late && (
              <Box display={"flex"} gap={0.5} alignItems={"center"}>
                <PanoramaFishEyeIcon
                  sx={{
                    color: "#E41B1B",
                    bgcolor: "#FCE8E8",
                    fontSize: "10px",
                    borderRadius: 5,
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
        {id && (
          <Grid item sm={12} md={6}>
            <Grid container spacing={1.5} justifyContent="end">
              {permissions?.maintenance?.view &&
                [
                  "maintenancing",
                  "finished",
                  "wait_maintenance",
                  "draft",
                ].includes(status) && (
                  <Grid item sm={5.5} md={4}>
                    <CustomizedMenuOptions
                      fullWidth
                      size="medium"
                      label={"พิมพ์เอกสาร"}
                      options={printOptions}
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        if (
                          value.innerText ===
                          `พิมพ์${t("logistic.maintenance.index")}`
                        ) {
                          navigate(`/logistic/maintenance/${id}/pdf`);
                        }
                      }}
                    />
                  </Grid>
                )}
              {selectModifyOptions?.length > 0 && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "คัดลอก":
                          openCopyConfirmation();
                          break;
                        case "ยกเลิก":
                          openCancelConfirmation();
                          break;
                        case "ได้รับเงินเคลมครบ":
                          openFullyPaidInsuranceConfirmation();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!id}
                  />
                </Grid>
              )}
              {showCreateOptions &&
                selectCreateOptions?.length > 0 &&
                status === "finished" && (
                  <Grid item sm={5.5} md={4}>
                    <CustomizedMenuOptions
                      fullWidth
                      size="medium"
                      label={"สร้าง"}
                      options={selectCreateOptions}
                      variant="contained"
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        const maintenance = getValues();
                        switch (value.innerText) {
                          case `${t("inventory.goods_issue.index")}`:
                            navigate("/inventory/goods-issue/add", {
                              state: createGoodsIssueFromMaintenance(
                                maintenance,
                                authUser
                              ),
                            });
                            break;
                          default:
                        }
                      }}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.maintenance.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("logistic.maintenance.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={!!id}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.maintenance.type")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledSelect
                name="type"
                control={control}
                error={Boolean(errors?.type)}
                options={typeOptions}
                helperText={errors?.type && errors?.type.message?.toString()}
                placeholder={t("logistic.maintenance.reference_no")}
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.maintenance.reference_document_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box>
                {filteredRefDocument && filteredRefDocument?.length > 0
                  ? filteredRefDocument.map((reference) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          window.open(
                            `/logistic/${reference.document_type?.replace(
                              "_",
                              "-"
                            )}/${reference.document_id}`,
                            "_blank"
                          );
                        }}
                        value={reference.document_unique_id}
                        isRounded
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.maintenance.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                error={Boolean(errors?.reference_no)}
                helperText={
                  errors?.reference_no &&
                  errors?.reference_no.message?.toString()
                }
                placeholder={t("logistic.maintenance.reference_no")}
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("logistic.maintenance.driver_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <DriverListForm
                disabledStatus={status === "finished"}
                maxSelectionLimit={1}
                hideCreator
                isDriver
                disabledRemoveCreator
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled || disabledStatus}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled || disabledStatus}
                viewMode={disabled || disabledStatus}
                required
              />
            </Grid>
          </Grid>
          <Grid item mb={1} />
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="ma_start_date"
                control={control}
                label={t("logistic.maintenance.ma_start_date")}
                disabled={disabled || disabledStatus}
                viewMode={disabled || disabledStatus}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="ma_end_date"
                control={control}
                label={t("logistic.maintenance.ma_end_date")}
                disabled={disabled || disabledStatus}
                viewMode={disabled || disabledStatus}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CancelConfirmation
        uniqueId={uniqueId}
        confirmation={cancelConfirmation}
        closeConfirmationHandler={closeCancelConfirmation}
        submitConfirmationHandler={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <Confirmation
        title="ได้รับเงินเคลมครบแล้ว"
        open={fullyPaidInsuranceConfirmation}
        handleClose={closeFullyPaidInsuranceConfirmation}
        action={submitFullyPaidInsuranceConfirmation}
        message={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box mb={1}>
              <GreenCheckIcon size={64} />
            </Box>
            <Box>
              <Typography>หากยืนยันแล้ว จะไม่สามารถเปลี่ยนแปลงได้</Typography>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default MaintenanceHeader;

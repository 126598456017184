import dayjs from "dayjs";
import { IVehicle } from "types/Logistic/vehicle";
import { stringMaxInput } from "utils/Validate";
import * as Yup from "yup";

export const vehicleSchema: IVehicle = {
  unique_id: "",
  register_id: "",
  type: undefined,
  driver: [],
  current_kilometer: undefined,
  due_distance_kilometer: undefined,
  detail: "",
  trailer_unique_id: "",
  trailer_id: undefined,
  trailer: undefined,
  act_id: "",
  act_end_date: undefined,
  status: "",
  brand: "",
  model: "",
  created_date: dayjs(),
  last_updated_date: dayjs(),
  created_by: undefined,
  status_note: "",
  image: [],
  insurance_product: {
    policy_id: "",
    protection_value: undefined,
    type: undefined,
    tier: "",
    start_date: undefined,
    end_date: undefined,
  },
  insurance_vehicle: {
    policy_id: "",
    protection_value: undefined,
    type: undefined,
    tier: "",
    start_date: undefined,
    end_date: undefined,
  },
  main_tire: [],
  spare_tire: [],
};

export const vehicleValidation = Yup.object().shape({
  unique_id: stringMaxInput(
    15,
    "รหัสรถต้องไม่เกิน 15 ตัวอักษร",
    true,
    "กรุณาระบุรหัสรถ"
  ),
  register_id: stringMaxInput(
    200,
    "เลขทะเบียนรถต้องไม่เกิน 200 ตัวอักษร",
    true,
    "กรุณาระบุเลขทะเบียนรถ"
  ),
  type: Yup.string().required("กรุณาระบุประเภท"),
  current_kilometer: Yup.string()
    .required("กรุณาระบุเลขกิโลเมตรปัจจุบัน")
    .max(200, "เลขกิโลเมตรเช็คระยะไม่สามารถเกิน 200 หลักได้")
    .test(
      "is-less-than-due-distance-kilometer",
      "กรุณาระบุเลขกิโลเมตรให้ถูกต้อง",
      function (value) {
        const dueDistanceKilometer = this.resolve(
          Yup.ref("due_distance_kilometer")
        ) as string;
        return (
          dueDistanceKilometer == null ||
          parseInt(value) <= parseInt(dueDistanceKilometer)
        );
      }
    ),
  due_distance_kilometer: Yup.string()
    .required("กรุณาระบุเลขกิโลเมตรเช็คระยะ")
    .max(200, "เลขกิโลเมตรเช็คระยะไม่สามารถเกิน 200 หลักได้")
    .test(
      "is-less-than-due-distance-kilometer",
      "กรุณาระบุเลขกิโลเมตรให้ถูกต้อง",
      function (value) {
        const currentKilometer = this.resolve(
          Yup.ref("current_kilometer")
        ) as string;
        return (
          currentKilometer == null ||
          parseInt(value) >= parseInt(currentKilometer)
        );
      }
    ),
  brand: stringMaxInput(200, undefined, false, undefined),
  model: stringMaxInput(200, undefined, false, undefined),
  detail: stringMaxInput(200, undefined, false, undefined),
  act_id: stringMaxInput(200, undefined, false, undefined),

  status_note: Yup.string().when("status", {
    is: "in_active",
    then: (schema) =>
      schema
        .required("กรุณาระบุหมายเหตุสถานะ")
        .max(200, `กรอกได้ไม่เกิน 200 ตัวอักษร`),
    otherwise: (schema) => schema.notRequired(),
  }),
  related_user_list: Yup.array().max(2, "ระบุพนักงานขนส่งได้ไม่เกิน 2 คน"),
  insurance_product: Yup.object().shape({
    tier: stringMaxInput(200, undefined, false, undefined),
    policy_id: stringMaxInput(200, undefined, false, undefined),
    start_date: Yup.date()
      .nullable()
      .test(
        "is-less-than-end_date",
        "กรุณาระบุวันที่เริ่มของประกันสินค้าให้ถูกต้อง",
        function (value) {
          const endDate = this.resolve(Yup.ref("end_date"));
          if (!value) return true;
          if (!endDate) return true;
          return endDate === null || value === null || value < endDate;
        }
      ),
    end_date: Yup.date().nullable(),
  }),
  insurance_vehicle: Yup.object().shape({
    tier: stringMaxInput(200, undefined, false, undefined),
    policy_id: stringMaxInput(200, undefined, false, undefined),
    start_date: Yup.date()
      .nullable()
      .test(
        "is-less-than-end_date",
        "กรุณาระบุวันที่เริ่มของประกันรถให้ถูกต้อง",
        function (value) {
          const endDate = this.resolve(Yup.ref("end_date"));
          if (!value) return true;
          if (!endDate) return true;
          return endDate === null || value === null || value < endDate;
        }
      ),
    end_date: Yup.date().nullable(),
  }),
});

export const vehicleTireSchema = {
  index: undefined,
  tire_id: "",
  serial: "",
  position: "",
  remark: "",
  band: "",
  model: "",
  current_distance: "",
  due_distance: "",
  action_date: dayjs(),
};

export const vehicleTireValidation = Yup.object().shape({
  position: stringMaxInput(200, undefined, true, "กรุณาระบุ "),
  action_date: Yup.date().required("กรุณาระบุ"),
});

import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateFilterParams } from "utils/AgGridFilter";
import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "components/Custom/CustomizedLetterAvatar";
import { formatNumber } from "utils/Global";

import { formatDate } from "utils/Date";

export const useTraceEntryColumnDefs = (fixedItemUniqueId?: Boolean) => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "scanned_date",
        headerName: t("inventory.trace.posted_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        sort: "desc",
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
      },
      {
        field: "created_date",
        headerName: t("date.created_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
      },
      {
        field: "source_warehouse_unique_id",
        headerName: t("inventory.item.warehouse.source_warehouse_unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "source_warehouse_name",
        headerName: t("inventory.item.warehouse.source_warehouse_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "source_bin_location_name",
        headerName: t("inventory.item.warehouse.source_bin_location_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "destination_warehouse_unique_id",
        headerName: t(
          "inventory.item.warehouse.destination_warehouse_unique_id"
        ),
        filter: "agTextColumnFilter",
      },
      {
        field: "destination_warehouse_name",
        headerName: t("inventory.item.warehouse.destination_warehouse_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "destination_bin_location_name",
        headerName: t("inventory.item.warehouse.destination_bin_location_name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "item_unique_id",
        headerName: t("inventory.item.unique_id"),
        filter: "agTextColumnFilter",
        floatingFilter: !fixedItemUniqueId,
      },
      {
        field: "item_name",
        headerName: t("inventory.item.name"),
        filter: "agTextColumnFilter",
      },
      {
        field: "lot_date",
        headerName: t("date.lot_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
      },
      {
        field: "serial_no",
        headerName: t("inventory.item.serial_no"),
        filter: "agTextColumnFilter",
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            return params.value;
          } else {
            return "-";
          }
        },
      },
      {
        field: "barcode",
        headerName: t("inventory.item.barcode"),
        filter: "agTextColumnFilter",
      },
      {
        field: "finished_qty",
        headerName: t("inventory.trace.finished_qty"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) => {
          return formatNumber(params.value);
        },
      },
      {
        field: "uom",
        headerName: t("inventory.item.warehouse.uom.index"),
        filter: "agTextColumnFilter",
      },
      {
        field: "type",
        headerName: t("inventory.trace.type"),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            "good_receive",
            "good_issue",
            "good_transfer",
            "good_adjust",
          ],
          valueFormatter: ({ value }: { value: string }) => {
            switch (value) {
              case "good_receive":
                return t("inventory.goods_receive.index");
              case "good_issue":
                return t("inventory.goods_issue.index");
              case "good_transfer":
                return t("inventory.goods_transfer.index");
              case "good_adjust":
                return t("inventory.goods_adjust.index");
              default:
                return "-";
            }
          },
        },
        valueFormatter: ({ value }: { value: string }) => {
          switch (value) {
            case "good_receive":
              return t("inventory.goods_receive.index");
            case "good_issue":
              return t("inventory.goods_issue.index");
            case "good_transfer":
              return t("inventory.goods_transfer.index");
            case "good_adjust":
              return t("inventory.goods_adjust.index");
            default:
              return "-";
          }
        },
        width: 240,
      },
      {
        field: "reference_unique_id",
        headerName: t("sentence.reference_unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "scanned_by_name",
        headerName: t("inventory.trace.scanned_by"),
        filter: "agTextColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, any>) => {
          if (params.value) {
            const { scanned_by_img_url, scanned_by } = params.data;
            if (scanned_by_img_url?.length > 0) {
              return (
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: scanned_by.user_unique_id,
                      first_name: scanned_by.first_name,
                      last_name: scanned_by.last_name,
                      img_url: scanned_by_img_url,
                    },
                  ]}
                />
              );
            } else {
              return <CustomizedLetterAvatar name={params.value} />;
            }
          }
        },
        minWidth: 200,
        flex: 1,
      },
    ]);
  }, [t, fixedItemUniqueId]);
  return columnDefs;
};

import { forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  IServerSideDatasource,
} from "ag-grid-community";

import ModalUI from "../ModalUI";
import ButtonLayout from "components/UI/ButtonLayout";
import CustomizedButton from "components/Custom/CustomizedButton";
import { useItemColumnDefs } from "components/Table/Inventory/Item/columnDefs";

import { ItemType } from "generated/wms";
import { ITEM_VIEW } from "services/AgGrid/ItemAggrid";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import AgGrid from "components/UI/AgGrid";

interface Props {
  itemModal: boolean;
  closeItemModalHandler: () => void;
  addItemsHandler: () => void;
  isService?: boolean;
  isIncome?: boolean;
  isExpenses?: boolean;
}

const ItemTableModal = forwardRef<any, Props>(
  (
    {
      itemModal,
      closeItemModalHandler,
      addItemsHandler,
      isService,
      isIncome,
      isExpenses,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("wms");

    const columnDefs: ColDef[] = useItemColumnDefs({
      type: null,
      active_only: true,
      is_item_modal: true,
      isCheckbox: true,
      isIncome: isIncome,
      isExpenses: isExpenses,
    });

    const datasource: IServerSideDatasource = {
      async getRows(params) {
        const { request } = params;
        const { startRow, endRow, filterModel, sortModel } = request;
        const { is_active, tag_list, cate_1, ...otherFilter } = filterModel;
        const formatFilter = {
          ...otherFilter,
          id: isIncome
            ? {
                filterType: "number",
                operator: "AND",
                condition1: {
                  filterType: "number",
                  type: "notEqual",
                  filter: 157,
                },
                condition2: {
                  filterType: "number",
                  type: "notEqual",
                  filter: 158,
                },
              }
            : isExpenses
            ? {
                filterType: "number",
                operator: "AND",
                condition1: {
                  filterType: "number",
                  type: "notEqual",
                  filter: 157,
                },
                condition2: {
                  filterType: "number",
                  type: "notEqual",
                  filter: 158,
                },
              }
            : // {
              //     filterType: "number",
              //     operator: "AND",
              //     condition1: {
              //       filterType: "number",
              //       operator: "AND",
              //       condition1: {
              //         filterType: "number",
              //         type: "notEqual",
              //         filter: 157,
              //       },
              //       condition2: {
              //         filterType: "number",
              //         type: "notEqual",
              //         filter: 158,
              //       },
              //     },
              //     condition2: {
              //       filterType: "number",
              //       type: "notEqual",
              //       filter: 160,
              //     },
              //   }
              undefined,
          is_active: is_active && {
            ...is_active,
            values: is_active.values.map((v: string) => parseInt(v)),
          },
          tag_list: tag_list && {
            type: "hasSome",
            filterType: "array",
            values: tag_list.values,
          },
          cate_1: cate_1 && {
            type: "in",
            filterType: "set",
            values: cate_1.values,
          },
        };
        try {
          const { ItemsViewAggrid } = await graphQLClientWithHeaderItem.request(
            ITEM_VIEW,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            }
          );
          params.success({
            rowData: ItemsViewAggrid.results as any[],
            rowCount: ItemsViewAggrid.count as number,
          });
        } catch (err) {
          params.fail();
        }
      },
    };

    const onGridReady = (params: GridReadyEvent) => {
      params.api.setFilterModel({
        type: {
          type: "set",
          values: [isService ? ItemType.Service : ItemType.Normal],
        },
        is_active: {
          type: "set",
          values: ["1"],
        },
      });
      params.api.setServerSideDatasource(datasource);
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
      return params.data.unique_id;
    }, []);

    return (
      <ModalUI
        open={itemModal}
        handleClose={closeItemModalHandler}
        title="สินค้า"
        maxWidth="lg"
        action={
          <ButtonLayout>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              size="medium"
              onClick={closeItemModalHandler}
            />
            <CustomizedButton
              title={t("button.confirm")}
              onClick={addItemsHandler}
              variant="contained"
              size="medium"
            />
          </ButtonLayout>
        }
      >
        <AgGrid
          ref={ref}
          columnDefs={columnDefs}
          height={665}
          onGridReady={onGridReady}
          rowSelection="multiple"
          getRowId={getRowId}
          rowMultiSelectWithClick
        />
      </ModalUI>
    );
  }
);

export default ItemTableModal;

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";

import { IBillingNote } from "types/Sales/billing-note";
import {
  BillingNoteCreateInput,
  BillingNoteFindUniqueQuery,
  BillingNoteUpdateInput,
  BillingNoteCancelMutation,
  useBillingNoteCreateMutation,
  useBillingNoteFindUniqueQuery,
  useBillingNoteUpdateMutation,
  useBillingNoteCancelMutation,
  ActivityType,
  ActivityLogDocumentType,
  SalesModelType,
} from "generated/sales";

import { useActivityLog } from "hooks/use-activity-log";
import { useDisable } from "hooks/use-disable";

import { useModal } from "hooks/use-modal";

import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import {
  billingNoteQueryFormatter,
  billingNoteCreatePayloadFormatter,
  billingNoteUpdatePayloadFormatter,
} from "utils/Formatter/Sales/BillingNote";

import { Stack } from "@mui/material";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import LoadingUI from "components/UI/LoadingUI";
import SalesFooter from "components/Form/Sales/Footer";
import BottomNavbar from "components/UI/Navbar/BottomNavbar";
import BillingNoteHeader from "components/Form/Sales/BillingNote/Header";
import CustomerInfo from "components/Form/Sales/CustomerInfo/BillingNoteCustomerInfo";
import AddressInfo from "components/Form/Sales/CustomerInfo/AddressInfo";
import DeliveryTripList from "components/Table/Sales/BillingNote/DeliveryTripList";
import { DeliveryTripListProvider } from "contexts/billing-note-context";
import AcceptModal from "components/Form/Sales/Accept/AcceptModal";
import SalesAcceptForm from "components/Form/Sales/Accept";

import { errorMessageFormatter } from "utils/Global";

const BillingNoteDocumentInfoTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const {
    state: { permissions },
  } = useStateContext();

  const { createActivityLog } = useActivityLog();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const {
    reset,
    watch,
    handleSubmit,
    trigger,
    getValues,
    setError,
    formState: { isDirty, dirtyFields, isValid },
  } = useFormContext<IBillingNote>();

  const status = watch("aggrid_status");

  const { data, isLoading, isSuccess, refetch } =
    useBillingNoteFindUniqueQuery<BillingNoteFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 10,
      }
    );

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { BillingNoteFindUnique } = data;

      const getBillingNoteData = async () => {
        const formatted = billingNoteQueryFormatter(BillingNoteFindUnique);
        reset(formatted);
        setIsLoadingData(false);
      };
      getBillingNoteData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (id && (status !== "draft" || !permissions?.billing_note?.update)) {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status, permissions]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        due_date: dayjs(),
        issue_date: dayjs(),
      });
    }
  }, [reset, state]);

  const { mutateAsync: create, isLoading: isCreating } =
    useBillingNoteCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useBillingNoteUpdateMutation<Error>(graphQLClient);

  const { mutateAsync: cancel, isLoading: isCancelling } =
    useBillingNoteCancelMutation<BillingNoteCancelMutation>(graphQLClient);

  const draftHandler = async (data: IBillingNote) => {
    if (!id) {
      try {
        const formatData = billingNoteCreatePayloadFormatter(data, "draft");
        const { BillingNoteCreate } = await create({
          createInput: formatData as BillingNoteCreateInput,
        });
        enqueueSnackbar(t("sales.billing_note.create.success"), {
          variant: "success",
        });
        navigate(`/sales/billing-note/${BillingNoteCreate?.id}`);

        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.BillingNote,
            reference_id: BillingNoteCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: BillingNoteCreate?.id,
                unique_id: BillingNoteCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.BillingNote,
          reference_id: BillingNoteCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || t("sales.billing_note.create.fail"), {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = await billingNoteUpdatePayloadFormatter(
          data,
          "draft"
        );
        const { BillingNoteUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatData as BillingNoteUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });

        await refetch();

        if (isDirty && Object.keys(dirtyFields).length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.BillingNote,
            reference_id: BillingNoteUpdate?.id || 0,
            activity_detail: {
              secondary_operation: ActivityType.Edit,
            },
          });
        }
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const waitPaymentHandler = async (data: IBillingNote) => {
    if (!id) {
      try {
        const formatData = billingNoteCreatePayloadFormatter(
          data,
          "wait_payment"
        );

        const { BillingNoteCreate } = await create({
          createInput: formatData as BillingNoteCreateInput,
        });
        enqueueSnackbar(t("sales.billing_note.create.success"), {
          variant: "success",
        });
        navigate(`/sales/billing-note/${BillingNoteCreate?.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.BillingNote,
            reference_id: BillingNoteCreate?.id || 0,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: BillingNoteCreate?.id,
                unique_id: BillingNoteCreate?.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.BillingNote,
          reference_id: BillingNoteCreate?.id || 0,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "wait_payment",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");
        enqueueSnackbar(formatError || t("sales.billing_note.create.fail"), {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = await billingNoteUpdatePayloadFormatter(
          data,
          "wait_payment"
        );
        const { BillingNoteUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatData as BillingNoteUpdateInput,
        });
        enqueueSnackbar(t("sales.billing_note.status_change.success"), {
          variant: "success",
        });

        await refetch();

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.BillingNote,
          reference_id: BillingNoteUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: "wait_payment",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err, "document");

        enqueueSnackbar(
          formatError || t("sales.billing_note.status_change.fail"),
          {
            variant: "error",
          }
        );
      }
    }
  };

  const validatePaymentHandler = () => {
    const accepted_date = getValues("accepted_date");
    if (!accepted_date) {
      setError("accepted_date", { message: "กรุณาระบุ" });
      enqueueSnackbar("กรุณาระบุข้อมูลที่จำเป็น", {
        variant: "error",
      });
      return;
    }
    submitPaymentModalHandler();
  };

  const paymentHandler = async () => {
    try {
      trigger();
      if (isValid) {
        const data = getValues();

        const formatData = await billingNoteUpdatePayloadFormatter(
          data,
          "fully_payment"
        );
        const { BillingNoteUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: formatData as BillingNoteUpdateInput,
        });
        enqueueSnackbar(`${t("sales.billing_note.status_change.success")}`, {
          variant: "success",
        });

        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.BillingNote,
          reference_id: BillingNoteUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "fully_payment",
            updated_fields:
              formattedDirtyFields?.length > 0
                ? formattedDirtyFields
                : undefined,
          },
        });
      }
    } catch (err) {
      const message = errorMessageFormatter(err);
      console.log("message", message);
      if (message?.split(",").length > 0) {
        enqueueSnackbar(
          ` มูลค่ารวมสุทธิ(วางบิล) เกินจากมูลค่ารวมสุทธิจากใบงานจัดส่ง
          ${message.replace(/,/g, ", ")}`,
          {
            variant: "error",
            style: {
              whiteSpace: "pre-line",
              maxWidth: 470,
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
            },
          }
        );
      } else
        enqueueSnackbar(`${t("sales.billing_note.status_change.fail")}`, {
          variant: "error",
        });
    }
  };

  const editHandler = async (data: IBillingNote, status?: string) => {
    try {
      const formatData = await billingNoteUpdatePayloadFormatter(
        data,
        status || data?.main_status || ""
      );
      const { BillingNoteUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: formatData as BillingNoteUpdateInput,
      });

      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });

      setDisabled(true);

      await refetch();

      if (status && data.aggrid_status !== status) {
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.BillingNote,
          reference_id: BillingNoteUpdate?.id || 0,
          activity_detail: {
            prev_status: data.aggrid_status,
            curr_status: status,
          },
        });
      } else {
        if (isDirty && Object.keys(dirtyFields).length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.BillingNote,
            reference_id: BillingNoteUpdate?.id || 0,
            activity_detail: {
              secondary_operation: ActivityType.Edit,
            },
          });
        }
      }
    } catch (err) {
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      await cancel({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.BillingNote,
        reference_id: parseInt(id!),
        activity_detail: {
          prev_status: status,
          curr_status: "cancelled",
        },
      });
      await refetch();
      enqueueSnackbar(t("sales.billing_note.cancel.success"), {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(t("sales.billing_note.cancel.fail"), {
        variant: "error",
      });
    }
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    reset();
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const {
    modal: paymentModal,
    openModalHandler: openPaymentModalHandler,
    closeModalHandler: closePaymentModalHandler,
    submitModalHandler: submitPaymentModalHandler,
  } = useModal(paymentHandler);

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft": {
        if (!permissions?.billing_note?.update) {
          return <></>;
        }
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              variant="contained"
              title={t("status.wait_payment")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(waitPaymentHandler)}
            />
          </>
        );
      }
      case "wait_payment":
        if (!permissions?.billing_note?.update) {
          return <></>;
        }
        if (!disabled) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                disabled={isUpdating || isCancelling}
                onClick={cancelEditHandler}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={() =>
                  handleSubmit((data) => editHandler(data, status))()
                }
                disabled={isUpdating || isCancelling}
              />
            </>
          );
        }
        return (
          <CustomizedButton
            title={t("sales.billing_note.payment.index")}
            variant="contained"
            onClick={openPaymentModalHandler}
            disabled={isUpdating || isCancelling}
          />
        );
      case "fully_payment":
      case "cancelled":
        break;
      default:
        if (status && !permissions?.billing_note?.update) {
          return <></>;
        }
        if (!status && !permissions?.billing_note?.create) {
          return <></>;
        }
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              variant="contained"
              title={t("status.wait_payment")}
              disabled={isCreating || isUpdating || isCancelling}
              onClick={handleSubmit(waitPaymentHandler)}
            />
          </>
        );
    }
  };

  if (
    id &&
    (isLoadingData || isLoading || isCreating || isUpdating || isCancelling)
  ) {
    return <LoadingUI />;
  }

  return (
    <>
      <BillingNoteHeader
        editClickHandler={editClickHandler}
        cancelHandler={cancelHandler}
      />
      <DeliveryTripListProvider>
        <CustomerInfo />
        <AddressInfo />
        <DeliveryTripList />
        <SalesFooter documentType={SalesModelType.BillingNote} />
      </DeliveryTripListProvider>
      {status === "fully_payment" && (
        <CustomizedBox margin={0}>
          <SalesAcceptForm documentType={SalesModelType.BillingNote} />
        </CustomizedBox>
      )}
      <AcceptModal
        open={paymentModal}
        handleClose={closePaymentModalHandler}
        onSubmit={validatePaymentHandler}
        documentType={SalesModelType.BillingNote}
      />
      <BottomNavbar>
        <Stack direction="row" spacing={1} alignItems="center">
          {renderButton(status)}
        </Stack>
      </BottomNavbar>
    </>
  );
};

export default BillingNoteDocumentInfoTab;

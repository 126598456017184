import { IGoodsIssue } from "types/Inventory/goodsIssue";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Traceability } from "generated/wms";
import { stringMaxInput } from "utils/Validate";

export const goodsIssueSchema: IGoodsIssue = {
  unique_id: "",
  type: "",
  reference_unique_id: "",
  source_warehouse_id: undefined,
  created_date: dayjs(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  created_by: {},
  main_status: "",
  aggrid_status: "",
  flag_status: [],
};

const goodsIssueTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่นำออก")
    .required("กรุณาระบุจำนวนที่นำออก")
    .when(
      "stock_qty",
      (value: any, schema) =>
        (value || value === 0) &&
        schema.max(value, "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง")
    ),
  remark: stringMaxInput(200, undefined, false),
});

const goodsIssueSerialValidation = Yup.object().shape({
  qty: Yup.number().when("traceability", (traceability: any) =>
    traceability && traceability[0] === Traceability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .positive("กรุณาระบุจำนวนที่นำออก")
          .required("กรุณาระบุจำนวนที่นำออก")
          .when(
            "stock_qty",
            (value: any, schema) =>
              (value || value === 0) &&
              schema.max(value, "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง")
          )
      : Yup.number().nullable()
  ),
  remark: stringMaxInput(200, undefined, false),
  serial_list: Yup.array().when("traceability", (traceability: any) =>
    traceability && traceability[0] === Traceability.Serial
      ? Yup.array()
          .of(goodsIssueTraceValidation)
          .min(1, "กรุณาเพิ่ม SN ในรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsIssueValidation = Yup.object().shape({
  unique_id: stringMaxInput(15, undefined, true, "กรุณาระบุเลขที่เอกสาร"),
  type: Yup.string().required("กรุณาระบุประเภท"),
  source_warehouse_id: Yup.string().required("กรุณาเลือกคลัง"),
  posted_date: Yup.date().required("กรุณาระบุ"),
  reference_unique_id: stringMaxInput(200, undefined, false),
  remark: stringMaxInput(200, undefined, false),
  trace_entry_list: Yup.array()
    .of(goodsIssueSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});

import dayjs from "dayjs";
import { MaintenanceFindUniqueQuery, SalesModelType } from "generated/sales";
import { TraceEntryType, Traceability } from "generated/wms";
import { IAttachment } from "types/global";
import { IMaintenance } from "types/Logistic/maintenance";
import { ITraceEntry } from "types/Inventory";
import { uploadFileToS3 } from "utils/s3";
import { formatString } from "utils/Global";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { VEHICLE_FIND_UNIQUE } from "services/Logistic/Vehicle/VehicleFindUnique";
import { v4 as uuidv4 } from "uuid";
import { IUser } from "types/Auth/user";
import { IGoodsIssue } from "types/Inventory/goodsIssue";

export const maintenanceCreatePayload = async (
  data: IMaintenance,
  status: string
) => {
  const {
    vehicle_id,
    trailer_id,
    has_trailer,
    created_date,
    last_updated_by,
    last_updated_date,
    ma_item_list,
    ma_list,
    attachment_list,
    type,
    is_insurance_fully_paid,
    unique_id,
    ...otherData
  } = data;

  const formatMaItemList = ma_item_list.map((element, index) => ({
    ...element,
    id: undefined,
    reference_unique_id: unique_id,
    indexing: index,
  }));

  const formatMaList = ma_list
    .map((element, index) => ({
      ...element,
      id: undefined,
      name: formatString(element.name),
      indexing: index,
    }))
    .filter((element) => element.name && element.name !== "");

  const attachmentList = attachment_list as any[];

  let uploadedAttachment: string[] = [];
  if (attachmentList && attachmentList.length > 0) {
    for (const file of attachmentList) {
      if (typeof file === "object") {
        const { Location } = await uploadFileToS3(file, "item", "");
        uploadedAttachment.push(Location);
      } else {
        uploadedAttachment.push(file);
      }
    }
  } else {
    uploadedAttachment = attachmentList;
  }

  const insuranceFullyPaidStatus =
    type === "CLAIM" ? (is_insurance_fully_paid ? true : false) : null;

  return {
    ...otherData,
    unique_id,
    type,
    vehicle_id: vehicle_id,
    trailer_id: trailer_id,
    ma_list: formatMaList,
    ma_item_list: formatMaItemList,
    attachment_list: uploadedAttachment,
    main_status: status,
    sub_status: status,
    is_insurance_fully_paid: insuranceFullyPaidStatus,
  };
};

export const maintenanceUpdatePayload = async (
  data: IMaintenance,
  status: string
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    created_by,
    created_date,
    attachment_list,
    claim_attachment_list,
    vehicle_id,
    trailer_id,
    has_trailer,
    trailer_details,
    ma_list,
    ma_item_list,
    ...otherData
  } = data;

  const attachmentList = attachment_list as any[];

  let uploadedAttachment: string[] = [];
  if (attachmentList && attachmentList.length > 0) {
    for (const file of attachmentList) {
      if (typeof file === "object") {
        const { Location } = await uploadFileToS3(
          file,
          "item",
          id?.toString() || ""
        );
        uploadedAttachment.push(Location);
      } else {
        uploadedAttachment.push(file);
      }
    }
  } else {
    uploadedAttachment = attachmentList;
  }

  let uploadedClaimAttachment: IAttachment[] = [];
  if (claim_attachment_list && claim_attachment_list.length > 0) {
    for (const file of claim_attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "maintenance",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedClaimAttachment.push(formatAttachment);
      } else {
        uploadedClaimAttachment.push(file);
      }
    }
  }

  const formatMaItemList = ma_item_list.map((element, index) => ({
    ...element,
    indexing: index,
  }));

  const formatMaList = ma_list
    .map((element, index) => ({
      ...element,
      name: formatString(element.name),
      indexing: index,
    }))
    .filter((element) => element.name && element.name !== "");

  return {
    ...otherData,
    vehicle_id: vehicle_id,
    trailer_id: trailer_id ?? null,
    trailer_details: trailer_details ?? null,
    ma_list: formatMaList,
    ma_item_list: formatMaItemList,
    attachment_list: uploadedAttachment,
    claim_attachment_list: uploadedClaimAttachment,
    main_status: status,
    sub_status: status,
    flag_status,
  };
};

export const maintenanceDataFormatter = (
  data: MaintenanceFindUniqueQuery["MaintenanceFindUnique"]
) => {
  if (data) {
    const {
      created_date,
      issue_date,
      ma_start_date,
      ma_end_date,
      last_updated_date,
      accident_date,
      trailer_id,
      type,
      is_insurance_fully_paid,
      ...otherData
    } = data;

    const insuranceFullyPaidStatus =
      type === "CLAIM" ? (is_insurance_fully_paid ? true : false) : null;

    const formatPayload = {
      ...otherData,
      trailer_id: trailer_id ?? undefined,
      has_trailer: trailer_id ? true : false,
      created_date: created_date ? dayjs(created_date) : undefined,
      issue_date: issue_date ? dayjs(issue_date) : undefined,
      ma_start_date: ma_start_date ? dayjs(ma_start_date) : undefined,
      ma_end_date: ma_end_date ? dayjs(ma_end_date) : undefined,
      last_updated_date: last_updated_date
        ? dayjs(last_updated_date)
        : undefined,
      accident_date: accident_date ? dayjs(accident_date) : undefined,
      type,
      is_insurance_fully_paid: insuranceFullyPaidStatus,
    };
    return formatPayload;
  }
};

export const copyMaintenanceHandler = async (data: IMaintenance) => {
  const {
    id,
    unique_id,
    reference_no,
    reference_document_list,
    main_status,
    sub_status,
    flag_status,
    aggrid_status,
    attachment_list,
    claim_attachment_list,
    created_by,
    created_date,
    last_updated_by,
    last_updated_date,
    issue_date,
    ma_start_date,
    ma_end_date,
    vehicle_details,
    trailer_details,
    pre_discount_amount,
    ma_item_list,
    ...otherData
  } = data;
  const graphqlClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { VehicleFindUnique } = await graphqlClient.request(
    VEHICLE_FIND_UNIQUE,
    {
      vehicleFindUniqueId: data.vehicle_id,
    }
  );

  let trailerFindUnique;

  if (data.trailer_id) {
    const { VehicleFindUnique: TrailerFindUnique } =
      await graphqlClient.request(VEHICLE_FIND_UNIQUE, {
        vehicleFindUniqueId: data.trailer_id,
      });
    trailerFindUnique = TrailerFindUnique;
  }

  const formattedMaItemList = ma_item_list.map((element, index) => ({
    ...element,
    id: undefined,
    unique_id: uuidv4(),
    indexing: index,
    qty_issued: 0,
  }));

  const formatData = {
    ...otherData,
    copied_id: id,
    copied_unique_id: unique_id,
    unique_id: "",
    main_status: "",
    sub_status: "",
    flag_status: [],
    attachment_list: [],
    claim_attachment_list: [],
    aggrid_status: "",
    created_date: dayjs().toISOString(),
    issue_date: dayjs().toISOString(),
    ma_start_date: dayjs().toISOString(),
    ma_end_date: dayjs().toISOString(),
    pre_discount_amount: pre_discount_amount ?? 0,
    vehicle_details: {
      ...vehicle_details,
      current_kilometer: VehicleFindUnique
        ? VehicleFindUnique.current_kilometer
        : vehicle_details.current_kilometer,
    },
    trailer_details: trailer_details?.vehicle_no
      ? {
          ...trailer_details,
          current_kilometer: trailerFindUnique
            ? trailerFindUnique.current_kilometer
            : trailer_details.current_kilometer,
        }
      : null,
    ma_item_list: formattedMaItemList,
  };

  return formatData;
};

export const formatMaintenaceItemListToGoodsIssueItemList = (
  data: IMaintenance
): ITraceEntry[] => {
  const formatData: ITraceEntry[] = data.ma_item_list
    .map((item) => ({
      unique_id: uuidv4(),
      reference_unique_id: "",
      type: TraceEntryType.GoodIssue,
      item_unique_id: item.item_unique_id || "",
      item_name: item.item_name || "",
      item_img_url: item.item_img_url || [],
      qty: 0,
      document_item_qty: item.qty,
      posted_qty: item.qty_issued,
      uom_id: item.uom_id || 0,
      uom: item.uom_name,
      remark: "",
      reference_line_item: {
        line_item_unique_id: item.unique_id,
        line_item_document_unique_id: data.unique_id || "",
        line_item_document_type: SalesModelType.Maintenance,
      },
      traceability: (item.traceability as Traceability) || Traceability.Normal,
      barcode: item.barcode,
      status: item.traceability === "serial" ? undefined : "is_active",
      stock_qty: item.stock_qty,
    }))
    .filter((item) => item.document_item_qty - item.posted_qty > 0);
  return formatData as ITraceEntry[];
};

export const createGoodsIssueFromMaintenance = (
  data: IMaintenance,
  currentUser: IUser | null
): IGoodsIssue => {
  const formatData = {
    unique_id: "",
    type: "maintenance",
    reference_unique_id: data.unique_id,
    remark: "",
    trace_entry_list: formatMaintenaceItemListToGoodsIssueItemList(data),
    created_by: {
      user_unique_id: currentUser?.unique_id,
      email: currentUser?.email,
      first_name: currentUser?.first_name,
      last_name: currentUser?.last_name,
      img_url: currentUser?.img_url,
    },
    main_status: "",
    aggrid_status: "",
    flag_status: [],
  };

  return formatData as IGoodsIssue;
};

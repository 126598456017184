import { useWatch, useFormContext } from "react-hook-form";
import { Typography } from "@mui/material";
import { IGoodsReceive } from "types/Inventory/goodsReceive";
import { numberFormatter } from "utils/Formatter/Global";

interface Props {
  nestedIndex: number;
}

const TotalPostedQuantityCell = ({ nestedIndex }: Props) => {
  const { control } = useFormContext<IGoodsReceive>();
  const watchSerialList =
    useWatch({
      control,
      name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
    }) || [];

  const totalPostedQuantity = watchSerialList.reduce((prev, curr) => {
    if (curr.qty) {
      if (typeof curr.qty === "string") {
        return prev + parseFloat(curr.qty);
      } else {
        return prev + curr.qty;
      }
    } else {
      return prev + 0;
    }
  }, 0);

  return (
    <Typography fontSize={14}>
      {numberFormatter(totalPostedQuantity)}
    </Typography>
  );
};

export default TotalPostedQuantityCell;

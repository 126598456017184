import { Fragment } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import CustomizedAvatar from "components/Custom/CustomizedAvatar";
import ControlledSelect from "components/Controller/ControlledSelect";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { IDefaultForm } from "types/global";
import { formatDateTime, formatDate } from "utils/Date";
import { checkIsNotDraftOrEmptyStatus } from "utils/Global";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";

import NewSerialStockQty from "./NewSerialStockQty";
import { numberFormatter } from "utils/Formatter/Global";

type Props = {
  disabled: IDefaultForm["disabled"];
  nestedIndex: number;
  status: string;
};

const SerialList = ({ nestedIndex, disabled, status }: Props) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IGoodsAdjust>();

  const { fields, remove } = useFieldArray({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const notDraft = checkIsNotDraftOrEmptyStatus(status);

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell />
          <TableCell
            align="left"
            sx={{
              px: 1,
              py: 1,
            }}
          >
            <Typography fontSize={14}>
              {formatDateTime(serial.scanned_date)}
            </Typography>
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell>
            <Typography>{numberFormatter(serial.stock_qty || 0)}</Typography>
          </TableCell>
          <TableCell>
            <ControlledNumberTextField
              fullWidth
              control={control}
              name={`trace_entry_list.${nestedIndex}.serial_list.${index}.qty`}
              decimalScale={2}
              InputProps={{
                inputProps: { min: 0 },
              }}
              error={Boolean(
                errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                  ?.qty
              )}
              FormHelperTextProps={{
                style: { fontSize: "10px", textAlign: "end" },
              }}
              sx={{
                pr: "0.5rem",
              }}
              viewMode={notDraft}
              allowNegative
            />
          </TableCell>
          <TableCell>
            <NewSerialStockQty nestedIndex={nestedIndex} index={index} />
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>{serial.uom}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>{serial.serial_no}</Typography>
          </TableCell>
          {/* <TableCell /> */}
          <TableCell>
            {serial.all_bin_locations &&
            serial.all_bin_locations.length > 1 &&
            !disabled ? (
              <ControlledSelect
                name={`trace_entry_list.${nestedIndex}.serial_list.${index}.source_bin_location_id`}
                control={control}
                label={""}
                error={Boolean(
                  errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                    ?.source_bin_location_id
                )}
                helperText={
                  errors?.trace_entry_list?.[nestedIndex]?.serial_list?.[index]
                    ?.source_bin_location_id?.message
                }
                onChange={(e: any) => {
                  const matchingBinLocation = serial.all_bin_locations?.find(
                    (bin) => bin.id === e.target.value
                  );
                  if (matchingBinLocation) {
                    setValue(
                      `trace_entry_list.${nestedIndex}.serial_list.${index}.stock_qty` as any,
                      matchingBinLocation.stock_qty
                    );
                  }
                }}
                options={
                  serial.all_bin_locations?.map((bin) => ({
                    value: bin.id,
                    label: bin.name,
                  })) || []
                }
              />
            ) : (
              <Typography fontSize={14}>
                {serial.source_bin_location?.name}
              </Typography>
            )}
          </TableCell>
          <TableCell>
            {serial.scanned_by && Object.keys(serial.scanned_by).length > 0 && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomizedAvatar
                  avatars={[
                    {
                      unique_id: serial.scanned_by.user_unique_id || "",
                      first_name: serial.scanned_by.first_name || "",
                      last_name: serial.scanned_by.last_name || "",
                      img_url: serial.scanned_by.img_url
                        ? serial.scanned_by.img_url[0]
                        : "",
                    },
                  ]}
                />
              </Box>
            )}
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>{serial.barcode}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={14}>
              {serial.is_lot ? formatDate(serial.lot_date) : "-"}
            </Typography>
          </TableCell>
          {!notDraft && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;

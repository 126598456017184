import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { formatNumber } from "utils/Global";

export const useItemCurrentStockByBarcodeColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "warehouse_unique_id",
        headerName: t("warehouse.unique_id"),
        filter: false,
        width: 120,
      },
      {
        field: "warehouse_name",
        headerName: t("warehouse.index"),
        filter: false,
        width: 150,
      },
      {
        field: "serial_no",
        headerName: t("inventory.item.serial_no"),
        filter: false,
        width: 220,
      },
      {
        field: "bin_name",
        headerName: t("warehouse.bin_location.index"),
        filter: false,
        width: 120,
      },
      {
        field: "stock_qty",
        headerName: t("inventory.item.stock_qty"),
        filter: false,
        width: 150,
        valueFormatter: (params) =>
          params.value ? formatNumber(params.value) : "",
      },
      {
        field: "lot_date",
        headerName: t("date.lot_date"),
        filter: false,
        width: 140,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params && params.value) {
            return formatDate(params.value);
          } else {
            return "-";
          }
        },
      },
      {
        field: "barcode",
        headerName: t("inventory.item.barcode"),
        filter: false,
        width: 300,
      },
    ]);
  }, [t]);
  return columnDefs;
};

import { useEffect, useState } from "react";
import { getBillingNoteCount } from "../../../utils/Formatter/Logistic/DeliveryTrip";

export const useBillingNoteStatus = (
  uniqueId?: string,
  status?: string | null
) => {
  const [isCancellable, setIsCancellable] = useState(true);

  useEffect(() => {
    const checkBillingNote = async () => {
      if (!uniqueId || status !== "finished") return;
      const count = await getBillingNoteCount(uniqueId);
      setIsCancellable(count === 0);
    };
    checkBillingNote();
  }, [uniqueId, status]);

  return isCancellable;
};

import { gql } from "graphql-request";

export const MAINTENANCE_VIEW = gql`
  query MaintenanceViewAggrid($aggridInput: AnyAggridInput) {
    MaintenanceViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        main_status
        flag_status
        aggrid_status
        vehicle_id
        vehicle_no
        vehicle_license_plate
        trailer_no
        trailer_license_plate
        type
        issue_date
        garage_type
        garage_name
        accident_date
        net_amount
        is_insurance_fully_paid
        created_by
        created_by_object
        driver_list
        driver_object_list
        created_date
        last_updated_date
        ma_start_date
        ma_end_date
        reference_document_unique_id_list
        claim_no
      }
    }
  }
`;

export const DELIVERY_TRIP_FOR_BILLING_NOTE_VIEW = gql`
  query DeliveryTripForBillingNoteViewAggrid($aggridInput: AnyAggridInput) {
    DeliveryTripForBillingNoteViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        issue_date
        customer_unique_id
        customer_name
        vehicle_id
        vehicle_no
        vehicle_license_plate
        vehicle_type
        trailer_license_plate
        income_net_amount
        expenses_net_amount
        main_status
        flag_status
        aggrid_status
        work_list
        income_post_discount_amount
        income_vat_exempted_amount
        income_vat_0_percent_amount
        income_vat_7_percent_amount
        income_vat_amount
        income_net_amount
        income_withholding_tax_amount
        income_total_amount
        remark
        vehicle
        customer_details
        has_billing_note
        reference_document_list
        billing_note_unique_id_list
      }
    }
  }
`;

export const MAINTENANCE_FIND_UNIQUE = gql`
  query MaintenanceFindUnique($uniqueInput: MaintenanceUniqueInput!) {
    MaintenanceFindUnique(uniqueInput: $uniqueInput) {
      id
      unique_id
      type
      reference_no
      created_date
      created_by
      last_updated_date
      last_updated_by
      driver_list
      issue_date
      vehicle_id
      vehicle_details
      trailer_id
      trailer_details
      ma_details
      accident_date
      ma_start_date
      ma_end_date
      claim_no
      accident_location
      accident_description
      is_insurance_fully_paid
      price_vat_type
      ma_type
      ma_item_list {
        id
        unique_id
        indexing
        reference_unique_id
        item_id
        item_unique_id
        item_name
        item_img_url
        item_sku_desc
        reference_line_item
        qty
        qty_issued
        uom_id
        uom_name
        uom_conversion_list
        price_per_unit
        discount
        vat_percentage
        pre_vat_amount
        pre_discount_amount
        vat_amount
        additional_discount
        post_discount_amount
        net_amount
        stock_qty
        withholding_tax_type
        total_amount
        withholding_tax_value
        remark
        traceability
        barcode
      }
      ma_list {
        id
        indexing
        name
        description
      }
      pre_vat_amount
      additional_discount
      additional_discount_type
      post_discount_amount
      vat_exempted_amount
      vat_0_percent_amount
      vat_7_percent_amount
      vat_amount
      net_amount
      withholding_tax_amount
      total_amount
      remark
      attachment_list
      claim_attachment_list
      main_status
      sub_status
      flag_status
      aggrid_status
      reference_document_list
    }
  }
`;

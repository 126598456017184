import { useDisable } from "hooks/use-disable";
import { useEffect, useState } from "react";
// import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IHeaderTable } from "types/global";
// import { IDeliveryTrip } from "types/Logistic/deliveryTrip";

export const useExpensesListHeader = () => {
  // const { watch } = useFormContext<IDeliveryTrip>();
  const { t } = useTranslation();
  const [headers, setHeader] = useState<IHeaderTable[]>([]);
  const [disabled] = useDisable();

  // const mainStatus = watch("main_status");
  // const aggrid_status = watch("aggrid_status");
  // const flagStatus = watch("flag_status");

  // const late = flagStatus.includes("late");
  // const cancelled = flagStatus.includes("cancelled");

  // const status = late && !cancelled ? mainStatus : aggrid_status;

  // const isFinished = status === "finished";

  useEffect(() => {
    const defaultHeaders: IHeaderTable[] = [
      {
        thaiLabel: t("logistic.expenses_list.no"),
        width: 54,
        align: "left",
      },
      {
        thaiLabel: t("logistic.income_list.income"),
        width: 334,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.expenses_list.qty"),
        width: 134,
        align: "right",
        required: true,
      },
      {
        thaiLabel: t("logistic.expenses_list.uom"),
        width: 134,
        align: "left",
        required: true,
      },
      {
        thaiLabel: t("logistic.expenses_list.price_per_unit"),
        width: 134,
        align: "right",
        required: true,
      },
      {
        thaiLabel: t("logistic.expenses_list.pre_vat_amount"),
        width: 134,
        align: "right",
        required: true,
      },
      {
        thaiLabel: t("logistic.expenses_list.withholding_tax"),
        width: 134,
        align: "left",
      },
    ];

    if (!disabled) {
      setHeader([
        ...defaultHeaders,
        {
          label: "",
          thaiLabel: "",
          width: 35,
        },
      ]);
    } else {
      setHeader(defaultHeaders);
    }
  }, [disabled, t]);

  return headers;
};

import { Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";
import { numberFormatter } from "utils/Formatter/Global";

type Props = {
  nestedIndex: number;
  index: number;
};

const NewSerialStockQty = ({ nestedIndex, index }: Props) => {
  const { control } = useFormContext<IGoodsAdjust>();
  const watchTrace = useWatch({
    control,
    name: `trace_entry_list.${nestedIndex}.serial_list` as `trace_entry_list`,
  });

  const trace = watchTrace.at(index);

  return (
    <Typography fontSize={14}>
      {numberFormatter(Number(trace?.qty) + (trace?.stock_qty || 0))}
    </Typography>
  );
};

export default NewSerialStockQty;

import { IOptionsCreatable, ISelectOption } from "../types/global";

const DEFAULT_FLOAT_DECIMAL_DIGITS = 2;

export const contactChannelOptions: ISelectOption[] = [
  {
    label: "เบอร์โทรศัพท์",
    value: "phone",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Fax",
    value: "fax",
  },
  {
    label: "Line",
    value: "line",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Website",
    value: "website",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];

export const countryOptions: ISelectOption[] = [
  {
    label: "ไทย",
    value: "ไทย",
  },
];

export const phoneRegExp =
  /^(?:(\([0-9]{2,3}\)|[0-9]{2,3})?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))?$/;

export const faxReqExp =
  /^(?:(\([0-9]{2}\)|[0-9]{2})?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))?$/;

export const characterRegExp = /^[\u0E00-\u0E7Fa-zA-Z\d]+$/;

export const errorMessageFormatter = (error: any, type?: string) => {
  const uniqueIdError = (type?: string) => {
    switch (type) {
      case "item":
        return "รหัสสินค้าภายในนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
      case "warehouse":
        return "ไม่สามารถเพิ่มหรือแก้ไขได้ เนื่องจากมีข้อมูลอยู่แล้วในระบบ";
      case "document":
        return "เลขที่เอกสารนี้มีอยู่ในระบบแล้ว";
      case "vehicle":
        if (JSON.stringify(error).includes("uniqueId already exists")) {
          return "รหัสรถนี้มีอยู่ในระบบแล้ว";
        } else {
          return "เลขทะเบียนรถนี้มีอยู่ในระบบแล้ว";
        }
      case "tire":
        return "Serial ยางนี้มีอยู่ในระบบแล้ว";
      default:
        return "รหัสนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
    }
  };

  const nameError = (type?: string) => {
    switch (type) {
      case "warehouse":
        return "ไม่สามารถเพิ่มหรือแก้ไขได้ เนื่องจากมีข้อมูลอยู่แล้วในระบบ";
      default:
        return "ชื่อนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
    }
  };

  const formatError = JSON.stringify(error);

  if (
    formatError.includes("Unique constraint failed") ||
    formatError.includes("unique id has already existed") ||
    formatError.includes("uniqueId already exists") ||
    formatError.includes("registerId already exists")
  ) {
    if (
      formatError.includes(
        "Unique constraint failed on the fields: (`unique_id`)"
      ) ||
      formatError.includes("unique id has already existed") ||
      formatError.includes("uniqueId already exists") ||
      formatError.includes("registerId already exists") ||
      formatError.includes("Unique constraint failed on the fields: (`serial`)")
    ) {
      return uniqueIdError(type);
    }
    if (
      formatError.includes(
        "Unique constraint failed on the fields: (`seller_unique_id`)"
      )
    ) {
      return type === "item"
        ? "รหัสนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่"
        : "รหัสสินค้าภายในนี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`name`)")
    ) {
      return nameError(type);
    } else if (
      formatError.includes(
        "Unique constraint failed on the fields: (`barcode`)"
      )
    ) {
      return "Barcode นี้มีอยู่ในระบบแล้ว กรุณาระบุใหม่";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`phone`)")
    ) {
      return "เบอร์โทรศัพท์นี้มีในระบบแล้ว กรุณาระบุใหม่";
    } else if (
      formatError.includes("Unique constraint failed on the fields: (`email`)")
    ) {
      return "อีเมลนี้มีในระบบแล้ว กรุณาระบุใหม่";
    }
  } else if (formatError.includes("is in use")) {
    return "ไม่สามารถแก้ไขหรือลบได้เนื่องจากถูกใช้งานอยู่";
  } else if (formatError.includes("in use cannot delete")) {
    return "ไม่สามารถลบได้ เนื่องจากถูกใช้ในเอกสารที่กำลังดำเนินการ";
  } else if (formatError.includes("HQ already exists")) {
    return "มีสำนักงานใหญ่อยู่แล้ว กรุณาเลือกใหม่";
  } else if (formatError.includes("Cannot delete HQ.")) {
    return "ไม่สามารถแก้ไขหรือลบได้เนื่องจากเป็นสำนักงานใหญ่";
  } else if (formatError.includes("Department is being used.")) {
    return "ไม่สามารถแก้ไขหรือลบได้เนื่องจากแผนกนี้ถูกนำไปใช้งานแล้ว";
  } else if (
    formatError.includes("Cannot remove a tire that has already been used.")
  ) {
    return "ไม่สามารถลบได้ เนื่องจากถูกอ้างอิงในเอกสาร";
  } else if (formatError.includes("invalid credentail")) {
    return "รหัสผ่านไม่ถูกต้อง";
  } else if (
    formatError.includes("เกินจำนวนสั่งซื้อ") ||
    formatError.includes("เกินจำนวนส่งคืน") ||
    formatError.includes("เกินจำนวนแจ้งซ่อม") ||
    formatError.includes("มูลค่าคงเหลือ")
  ) {
    return error.response?.errors?.[0].message.split(" ")[0] || "";
  }
  return;
};

export const parseFloatFunction = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  field: any
) => {
  const value = e.target.value;
  return field.onChange(parseFloat(value));
};

export const checkIsNotDraftOrEmptyStatus = (status: string) =>
  !["", "draft", "not_approved"].includes(status);

export const entryTypeEngToThai = (data: string) => {
  switch (data) {
    case "goods_receive":
      return "นำเข้า";
    case "goods_return":
      return "รับคืน";
    case "goods_issue":
      return "นำออก";
    case "goods_transfer":
      return "โอนย้าย";
    case "goods_adjustment":
    case "goods_adjust":
      return "ปรับปรุง";
    default:
      return "";
  }
};

export const formatString = (str: any) => {
  return str?.toString().trim();
};

export const formatStringArray = (
  arr: string[] | string,
  filterInvalid?: boolean
) => {
  if (typeof arr === "string") {
    return [arr];
  }
  const result = Array.isArray(arr)
    ? arr?.map((item) => item?.toString().trim())
    : [];
  if (filterInvalid && result?.length > 0) {
    return result?.filter((item) => item);
  }
  return result;
};

export const formatStringToArray = (str: string, denominator?: string) => {
  if (str) {
    return str?.split(denominator || ",")?.map((item) => item?.trim()) || [];
  }
  return [];
};

export const formatPriceTwoDecimal = (input: any) => {
  return typeof input === "string"
    ? Math.round((parseFloat(input.replace(",", "")) + Number.EPSILON) * 100) /
        100
    : Math.round((input + Number.EPSILON) * 100) / 100;
};

export const formatFloat = (input: any, points?: number) => {
  return !isNaN(parseFloat(input))
    ? parseFloat(
        parseFloat(input).toFixed(points || DEFAULT_FLOAT_DECIMAL_DIGITS)
      )
    : undefined;
};

export const formatInt = (input: any) => {
  return !isNaN(parseInt(input)) ? parseInt(input) : undefined;
};

export const formatNumber = (number: number = 0, isInt?: boolean) => {
  return (Math.round((number + Number.EPSILON) * 100) / 100)?.toLocaleString(
    undefined,
    {
      minimumFractionDigits: isInt ? 0 : 2,
      maximumFractionDigits: isInt ? 0 : 2,
    }
  );
};

export const paymentNameOptions: IOptionsCreatable[] = [
  {
    label: "เงินสด",
    value: "เงินสด",
  },
  {
    label: "บัญชีธนาคาร",
    value: "บัญชีธนาคาร",
  },
  {
    label: "เช็ค",
    value: "เช็ค",
  },
];

export const bankNameOptions: IOptionsCreatable[] = [
  {
    label: "KBANK",
    value: "KBANK",
  },
  {
    label: "SCB",
    value: "SCB",
  },
  {
    label: "BBL",
    value: "BBL",
  },
  {
    label: "Krungsri",
    value: "Krungsri",
  },
  {
    label: "KTB",
    value: "KTB",
  },
  {
    label: "LH BANK",
    value: "LH BANK",
  },
  {
    label: "TMBThanachart",
    value: "TMBThanachart",
  },
  {
    label: "KKP",
    value: "KKP",
  },
  {
    label: "MHCB",
    value: "MHCB",
  },
  {
    label: "mPAY",
    value: "mPAY",
  },
  {
    label: "TrueMoney",
    value: "TrueMoney",
  },
];

export const accountTypeOptions: IOptionsCreatable[] = [
  {
    label: "ออมทรัพย์",
    value: "ออมทรัพย์",
  },
  {
    label: "ฝากประจำ",
    value: "ฝากประจำ",
  },
  {
    label: "กระแสเงินสด",
    value: "กระแสเงินสด",
  },
  {
    label: "อื่นๆ",
    value: "อื่นๆ",
  },
];

export const handleChartReady = (chartWrapper: any) => {
  const chart = chartWrapper.getChart();
  if (chart) {
    const svg = chart.getContainer().getElementsByTagName("svg")[0];

    // Function to apply customizations to legend items
    const applyLegendCustomizations = () => {
      // Select all <g> elements that contain both a <rect> and <text> child element
      const legendGroups = Array.from(
        svg.querySelectorAll("g > g") as NodeListOf<SVGGElement>
      ).filter((group) => {
        return group.querySelector("rect") && group.querySelector("text");
      });

      // Adjust legend item spacing
      legendGroups.forEach((group, index) => {
        // const xOffset = -16 * index; // Uncomment and adjust to control spacing if needed
        // group.setAttribute("transform", `translate(${xOffset}, 0)`);S

        // Select the colored rectangle inside the legend group
        const rect = Array.from(group.querySelectorAll("rect")).find((r) => {
          const fill = r.getAttribute("fill");
          const fillOpacity = r.getAttribute("fill-opacity");
          return fill && fill !== "#ffffff" && fillOpacity !== "0"; // Exclude background rects
        }) as SVGRectElement | null;

        if (rect) {
          const x = rect.getAttribute("x");
          const y = rect.getAttribute("y");
          const fill = rect.getAttribute("fill");

          // Create a circle to replace the rectangle
          const circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
          );
          circle.setAttribute("cx", (parseFloat(x || "0") + 6).toString()); // Adjust center
          circle.setAttribute("cy", (parseFloat(y || "0") + 6).toString()); // Adjust center
          circle.setAttribute("r", "6");
          circle.setAttribute("fill", fill || "");

          // Replace the rect with the circle
          rect.parentNode?.replaceChild(circle, rect);
        }

        // const text = group.querySelector("text") as SVGTextElement | null;
        // if (text) {
        //   const rectX = parseFloat(rect?.getAttribute("x") || "0");
        //   text.setAttribute("x", (rectX + 16).toString()); // Adjust `14` to reduce or increase spacing
        // }
      });
    };

    // Apply the initial customizations
    applyLegendCustomizations();

    // Use a MutationObserver to reapply customizations on DOM changes
    const observer = new MutationObserver(() => {
      applyLegendCustomizations();
    });
    observer.observe(svg, { childList: true, subtree: true });

    // Disconnect observer when chart is destroyed to avoid memory leaks
    return () => observer.disconnect();
  }
};

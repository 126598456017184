import { v4 as uuidv4 } from "uuid";
import { Box, Collapse, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import CustomizedButton from "components/Custom/CustomizedButton";
import IncomeListTable from "components/Table/Logistic/IncomeList";
import Confirmation from "components/UI/Confirmation";
import ItemTableModal from "components/UI/Modal/CheckedModal/ItemTableModal";
import { useModal } from "hooks/use-modal";
import { useRef, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IItem } from "types/Inventory/item";
import { IIncomeList } from "types/Logistic";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";
import PostDiscountAmount from "components/Table/Logistic/IncomeList/PostDIscountAmount";
import IncomeSummary from "./IncomeSummary";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { vatItemTypeFormatter } from "utils/Formatter/Global";
import { useDisable } from "hooks/use-disable";
import { useParams } from "react-router-dom";

type Props = {
  isCancellable?: boolean;
};

const IncomeList  = ({ isCancellable }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const { control, watch } = useFormContext<IDeliveryTrip>();
  const [disabled] = useDisable();
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: "income_list",
  });
  const { t } = useTranslation();
  const { id } = useParams();

  const [openSummary, setOpenSummary] = useState<boolean>(false);

  const dragEndHandler = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState<boolean>(false);
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const openDeleteItemConfirmationHandler = (index: number) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(undefined);
  };

  const deleteItemConfirmationAction = () => {
    if (deletedIndex || deletedIndex === 0) {
      remove(deletedIndex);
    }
    closeDeleteItemConfirmationHandler();
  };

  const {
    modal: itemModal,
    openModalHandler: openItemModalHandler,
    closeModalHandler: closeItemModalHandler,
  } = useModal();

  const addItemsHandler = () => {
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    if (selectedItems) {
      selectedItems.forEach((item_details: IItem) => {
        let formatItems: IIncomeList = {
          unique_id: uuidv4(),
          item_id: item_details.id || 0,
          item_unique_id: item_details.unique_id || "",
          item_name: item_details.name || "",
          item_sku_desc: item_details.description,
          qty: 1,
          uom_id: item_details.stock_uom_id || 0,
          uom_name: item_details.stock_uom_name || "",
          uom_conversion_list: item_details.uom_conversion_list,
          discount: 0,
          pre_vat_amount: 0,
          price_per_unit: item_details.sale_price ?? 0,
          withholding_tax_type: "ยังไม่ระบุ",
          withholding_tax_value: 0,
          vat_percentage: vatItemTypeFormatter(
            item_details.sale_vat_type || ""
          ),
        };

        append(formatItems);
      });
    }
    closeItemModalHandler();
  };

  const mainStatus = watch("main_status");
  const aggrid_status = watch("aggrid_status");
  const flagStatus = watch("flag_status");

  const late = flagStatus.includes("late");
  const cancelled = flagStatus.includes("cancelled");

  const status = late && !cancelled ? mainStatus : aggrid_status;

  const disabledStatus = !id || ["wait_deliver", "delivering"].includes(status) || !isCancellable;

  return (
    <CustomizedBox maxWidth={1650}>
      <Box display="flex" gap={0.5}>
        <Typography fontWeight={600} mb={1}>
          {t("logistic.income_list.index")}
        </Typography>
        <Typography color="error.main">*</Typography>
      </Box>
      <IncomeListTable
        fields={fields}
        dragEndHandler={dragEndHandler}
        openDeleteItemConfirmationHandler={openDeleteItemConfirmationHandler}
        isCancellable={isCancellable}
      />
      <Box mt={2}>
        {!disabled && !disabledStatus && (
          <CustomizedButton
            startAddIcon
            variant="outlined"
            title={t("logistic.income_list.add")}
            onClick={openItemModalHandler}
          />
        )}
      </Box>

      <PostDiscountAmount />
      <Box display="flex" justifyContent={"flex-end"}>
        <CustomizedButton
          title="สรุปมูลค่า"
          variant="text"
          onClick={() => {
            setOpenSummary(!openSummary);
          }}
          endIcon={
            openSummary ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        />
      </Box>

      <Collapse in={openSummary}>
        <Box bgcolor={"#F2F2F266"} py={1}>
          <IncomeSummary />
        </Box>
      </Collapse>

      <Confirmation
        title="ลบรายการวางบิล"
        open={openDeleteItemConfirmation}
        handleClose={closeDeleteItemConfirmationHandler}
        action={deleteItemConfirmationAction}
      />
      <ItemTableModal
        ref={gridRef}
        itemModal={itemModal}
        closeItemModalHandler={closeItemModalHandler}
        addItemsHandler={addItemsHandler}
        isService
        isIncome
      />
    </CustomizedBox>
  );
};

export default IncomeList;

import { Typography } from "@mui/material";
import { Traceability } from "generated/wms";
import { useFormContext, useWatch } from "react-hook-form";
import { IGoodsAdjust } from "types/Inventory/goodsAdjust";
import { numberFormatter } from "utils/Formatter/Global";

type Props = {
  nestedIndex: number;
  traceability: Traceability;
};

const NewStockQty = ({ nestedIndex, traceability }: Props) => {
  const { control } = useFormContext<IGoodsAdjust>();
  const trace = useWatch({
    control,
    name: `trace_entry_list.${nestedIndex}`,
  });

  const serialList = trace.serial_list;

  if (traceability === Traceability.Normal) {
    return (
      <Typography fontSize={14}>
        {numberFormatter(Number(trace?.qty) + (trace?.stock_qty || 0))}
      </Typography>
    );
  } else {
    const totalNewStockQty = serialList?.reduce((prev, curr) => {
      if (curr.qty) {
        if (typeof curr.qty === "string") {
          return prev + parseFloat(curr.qty) + (curr?.stock_qty || 0);
        } else {
          return prev + curr.qty + (curr?.stock_qty || 0);
        }
      } else {
        return prev + 0 + (curr?.stock_qty || 0);
      }
    }, 0);
    return (
      <Typography fontSize={14}>
        {numberFormatter(totalNewStockQty || 0)}
      </Typography>
    );
  }
};

export default NewStockQty;

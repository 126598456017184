import { Typography } from "@mui/material";
import ControlledSelect from "components/Controller/ControlledSelect";
import { useDisable } from "hooks/use-disable";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { ISelectOption } from "types/global";
import { IDeliveryTrip } from "types/Logistic/deliveryTrip";

type Props = {
  field: FieldArrayWithId<IDeliveryTrip, "expenses_list", "id">;
  index: number;
  disabledStatus: boolean;
};

const UomCell = ({ field, index, disabledStatus }: Props) => {
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryTrip>();

  const options: ISelectOption[] = field.uom_conversion_list
    ? field.uom_conversion_list.length > 0
      ? [
          {
            label: field.uom_name || "",
            value: field.uom_id || "",
          },
          ...field.uom_conversion_list.map((uom) => ({
            label: uom.target_uom_name,
            value: uom.target_uom_id,
          })),
        ]
      : [
          {
            label: field.uom_name || "",
            value: field.uom_id || "",
          },
        ]
    : [];

  return (
    <>
      {!disabledStatus && !disabled ? (
        <ControlledSelect
          control={control}
          name={`expenses_list.${index}.uom_id`}
          options={options}
          error={Boolean(
            errors?.expenses_list && errors.expenses_list[index]?.uom_id
          )}
        />
      ) : (
        <Typography>{field.uom_name}</Typography>
      )}
    </>
  );
};

export default UomCell;

import { useState, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import ControlledDateTimePicker from "components/Controller/ControlledDateTimePicker";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import { useDisable } from "hooks/use-disable";
import ControlledTextField from "components/Controller/ControlledTextField";
import DocumentDropzoneUI from "components/UI/DocumentDropzoneUI";
import { SalesModelType } from "generated/sales";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

type Props = {
  isModal?: boolean;
  documentType: SalesModelType;
};

const title = {
  quotation: "การตอบรับใบเสนอราคา",
  billing_note: "แนบหลักฐานการชำระ",
  delivery_trip: "",
  maintenance: "",
};

const SalesAcceptForm = ({ isModal, documentType }: Props) => {
  const [disabled] = useDisable();
  const [isDefaultSet, setIsDefaultSet] = useState(false);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const attachmentList = useWatch({
    control,
    name: "attachment_list",
  });

  const acceptedDate = useWatch({
    control,
    name: "accepted_date",
  });

  const paymentDate = useWatch({
    control,
    name: "payment_date",
  });

  const exceptsDocument = useMemo(
    () => [SalesModelType.BillingNote, SalesModelType.Quotation],
    []
  );

  useEffect(() => {
    if (!isDefaultSet && !acceptedDate) {
      setValue("accepted_date", dayjs());
      setIsDefaultSet(true);
    } else if (!isDefaultSet && acceptedDate) {
      setIsDefaultSet(true);
    }
  }, [acceptedDate, isDefaultSet, setValue]);

  useEffect(() => {
    if (
      !isDefaultSet &&
      !paymentDate &&
      !exceptsDocument.includes(documentType)
    ) {
      setValue("payment_date", dayjs());
      setIsDefaultSet(true);
    } else if (!isDefaultSet && paymentDate) {
      setIsDefaultSet(true);
    }
  }, [paymentDate, isDefaultSet, setValue, exceptsDocument, documentType]);

  let enabledStatus: string;

  switch (documentType) {
    case "quotation":
      enabledStatus = "wait_accept";
      break;
    case "billing_note":
      enabledStatus = "wait_payment";
      break;
    case "delivery_trip":
      enabledStatus = "finished";
      break;
    default:
      enabledStatus = "wait_accept";
  }

  const datePicker = () => {
    switch (documentType) {
      case "quotation":
        return (
          <ControlledDateTimePicker
            name="accepted_date"
            control={control}
            label="วันที่"
            disabled={disabled && status !== enabledStatus}
            viewMode={disabled && status !== enabledStatus}
            required
          />
        );
      case "billing_note":
        return (
          <ControlledDatePicker
            name="accepted_date"
            control={control}
            label="วันที่"
            disabled={disabled && status !== enabledStatus}
            viewMode={disabled && status !== enabledStatus}
            required
          />
        );
      case "delivery_trip":
        return (
          <ControlledDatePicker
            name="payment_date"
            control={control}
            label="วันที่"
            disabled={disabled && status === enabledStatus}
            viewMode={disabled && status === enabledStatus}
            required
          />
        );
      default:
        return (
          <ControlledDateTimePicker
            name="accepted_date"
            control={control}
            label="วันที่"
            disabled={disabled && status !== enabledStatus}
            viewMode={disabled && status !== enabledStatus}
            required
          />
        );
    }
  };

  return (
    <>
      {!isModal && (
        <Typography fontWeight="bold">{title[documentType]}</Typography>
      )}
      {(isModal || documentType === SalesModelType.Quotation) && (
        <Grid container spacing={1.5} alignItems="center" mt={0}>
          <Grid item md={isModal ? 6 : 2}>
            {datePicker()}
          </Grid>
        </Grid>
      )}
      {(isModal || documentType === SalesModelType.Quotation) && (
        <>
          <Typography fontWeight="bold" mt={2}>
            {documentType === "delivery_trip" ? "รายละเอียด" : "หลักฐานอ้างอิง"}
          </Typography>
          {isModal ? (
            <ControlledTextField
              name={
                documentType === SalesModelType.DeliveryTrip
                  ? "payment_detail"
                  : "accepted_remark"
              }
              control={control}
              error={Boolean(errors.accepted_remark)}
              // disabled={disabled && status !== enabledStatus}
              disabled={
                documentType === SalesModelType.DeliveryTrip
                  ? disabled && status === enabledStatus
                  : disabled && status !== enabledStatus
              }
              viewMode={
                documentType === SalesModelType.DeliveryTrip
                  ? disabled && status === enabledStatus
                  : disabled && status !== enabledStatus
              }
              sx={{ mt: 2 }}
              multiline
              rows={4}
              placeholder={
                documentType === SalesModelType.DeliveryTrip ? "รายละเอียด" : ""
              }
              // viewMode={disabled && status !== enabledStatus}
            />
          ) : (
            <Grid container spacing={1.5} alignItems="center" mt={0}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <ControlledTextField
                  name={
                    documentType === SalesModelType.DeliveryTrip
                      ? "payment_detail"
                      : "accepted_remark"
                  }
                  control={control}
                  error={Boolean(errors.accepted_remark)}
                  // disabled={disabled && status !== enabledStatus}
                  // viewMode={disabled && status !== enabledStatus}
                  disabled={
                    documentType === SalesModelType.DeliveryTrip
                      ? disabled && status === enabledStatus
                      : disabled && status !== enabledStatus
                  }
                  viewMode={
                    documentType === SalesModelType.DeliveryTrip
                      ? disabled && status === enabledStatus
                      : disabled && status !== enabledStatus
                  }
                  placeholder={
                    documentType === SalesModelType.DeliveryTrip
                      ? "รายละเอียด"
                      : ""
                  }
                  sx={{ my: 2 }}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
      {documentType !== SalesModelType.DeliveryTrip &&
        (isModal || attachmentList?.length > 0) && (
          <DocumentDropzoneUI
            control={control}
            name="attachment_list"
            setValue={setValue}
            disabled={disabled && status !== enabledStatus}
            isModal={isModal}
            maxSize={5}
            multiple
            fullWidth={isModal}
            customGridSize={2.5}
            separateAttachmentDate={!isModal}
          />
        )}

      {documentType === SalesModelType.DeliveryTrip && isModal && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: 32,
            minWidth: 200,
            gap: "12px",
            backgroundColor: "rgba(255, 0, 0, 0.1)",
            padding: "4px 16px",
            borderRadius: "4px",
          }}
          mt={2}
        >
          <ErrorOutlineOutlinedIcon
            sx={{
              width: "14.545454978942871px",
              height: "14.545454978942871px",
              position: "relative",
              top: "0.73px",
              left: "0.73px",
              color: "red",
            }}
          />
          <Typography>
            {"หากบันทึกวันจ่ายแล้วจะไม่สามารถแก้ไขเอกสารได้อีก"}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SalesAcceptForm;

import { Typography, Box, Grid } from "@mui/material";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledTextField from "components/Controller/ControlledTextField";
import { CustomizedBox } from "components/Custom/CustomizedBox";
import { useDisable } from "hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form"
import { IDeliveryTrip } from "types/Logistic/deliveryTrip"

const DateOfPayment = () => {
    const {
        control,
    } = useFormContext<IDeliveryTrip>();
    const [disabled] = useDisable();

    const mainStatus = useWatch({
        control,
        name: "main_status",
    });
    const aggrid_status = useWatch({
        control,
        name: "aggrid_status",
    });
    const flagStatus = useWatch({
        control,
        name: "flag_status",
    });
    const paymentStatus = useWatch({
        control,
        name: "payment_status"
    })

    const late = flagStatus.includes("late");
    const cancelled = flagStatus.includes("cancelled");

    const status = late && !cancelled ? mainStatus : aggrid_status;

    const isShow = ["delivering", "finished", "cancelled"].includes(status);

    return (
        <CustomizedBox margin={"1rem 0rem 0rem 0rem"}>
            <Typography fontWeight={600} mb={1}>
                บันทึกการจ่ายเงินพนักงาน
            </Typography>
            <Grid container spacing={1.5}>
                <Grid item xs={12} sm={12} md={4}>
                    <ControlledDatePicker
                        control={control}
                        name="payment_date"
                        label={"วันที่"}
                        disabled={disabled || isShow}
                        viewMode={!!paymentStatus}
                        required
                    />
                </Grid>
                <Box width="100%" />
            </Grid>
            <Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <ControlledTextField
                        name="payment_detail"
                        control={control}
                        label={"รายละเอียด"}
                        disabled={disabled || isShow}
                        sx={{ mt: 2 }}
                        multiline
                        rows={4}
                        viewMode={!!paymentStatus}
                    />
                </Grid>
            </Grid>
        </CustomizedBox>
    );
};

export default DateOfPayment;